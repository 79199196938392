import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { TextCarios, TxtSubTitles } from "./Textos";
import img_1 from "./assets/confirmar_asistencia.png"
import { Auth } from "../../context";

const Confirmacion = ({bkImage, guest, evento, open, setOpen, bgcolor = "#FFF2EE"}) => {
        const authContext = useContext(Auth);
        const { confirmarAsistencia, confirmado } = authContext;
        const handleOpen = () => { setOpen(true) };
        const handleClose = () => { setOpen(false) };
        const [confirm, setConfirm] = useState(0);
        const [asistidos, setAsistidos] = useState(0);
      
        const aceptarAsistencia = (noAsistire = false) => {
          confirmarAsistencia(noAsistire ? 1010: asistidos)
          handleClose()
        }
      
        const onChange = e => {
          if(e?.target?.value && e?.target?.value > 0 && guest?.reservaciones){
            if(e?.target?.value <= guest?.reservaciones){
              setAsistidos(e.target.value)
            }
          } 
        }
      
        useEffect(()=>{
            console.log(confirmado)
            if(guest)setConfirm(confirmado ? true: false)
        },[confirmado])
      
        useEffect(()=> {
            console.log(guest.confirm)
            console.log(confirm)
          if(guest){
            setConfirm(guest?.confirm ? guest.confirm: 0)
            setAsistidos(guest?.reservaciones ? guest.reservaciones: 0)
          }  
        },[guest])
    
    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        borderRadius: "8px",
        p: 2
    };

    const TxtTitle = styled('p')({
        color: "black",
        fontFamily: "Belleza",
        fontWeight: "bold",
        fontSize: "18px"
    })
    
    const TxtSub = styled('p')({
        color: "black",
        fontSize: "12px",
        fontFamily: "Belleza"
    })

    return (<Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className='test-kdemslkamds'
            sx={{
            display: "grid",
            alignContent: "center",
            justifyContent: "center"
            }}
        >
            <Box sx={{outline: "0px !important", ...style}}>
                <Close onClick={handleClose} sx={{
                    cursor: "pointer",
                    color: "black !important",
                    position: "absolute",
                    top: "10px",
                    right: "10px"
                }}/>
                <TxtTitle>¿Deseas confirmar tu asistencia?</TxtTitle>
                <TxtSub sx={{marginTop: "10px"}}>
                    Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
                </TxtSub>
                <Grid container sx={{marginTop: "20px"}}>
                    <Grid item xs={5}>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <Button onClick={()=>{
                                    if(asistidos && asistidos > 0){
                                        if(asistidos < guest?.reservaciones){
                                            setAsistidos(asistidos+1)
                                        }
                                    } 
                                }}><Add sx={{color: "white !important", background: "black"}}/></Button>
                            </Grid>
                            <Grid item xs={8} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <TextField
                                    variant='standard'
                                    type='hidden'
                                    value={asistidos}
                                    onChange={onChange}
                                    // sx={{"& input":{color: "black !important", textAlign: "center", fontSize: "12px"}}}
                                />
                                <TxtSub sx={{fontWeight: "bold"}}>
                                    {asistidos} persona{asistidos > 1? "s": ""}
                                </TxtSub>
                            </Grid>
                            <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <Button onClick={()=>{
                                    if(asistidos && (asistidos-1) > 0 && guest?.reservaciones){
                                        setAsistidos(asistidos-1)
                                    } 
                                }}>
                                    <Remove sx={{color: "white !important", background: "black"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <TxtSub
                            onClick={() => aceptarAsistencia()}
                            sx={{
                                color: "white",
                                background: "black",
                                textAlign: "center",
                                padding: "7px 5px",
                                borderRadius: "5px"
                        }}>Confirmar</TxtSub>
                    </Grid> 
                </Grid>
            </Box>
        </Modal>
        <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
            <ParallaxBannerLayer translateY={[0, 0]} opacity={[-1, 3]} style={{zIndex: 3}}>
                <Typography sx={{
                    color: "#A76C40",
                    padding: "0px",
                    marginTop: "20px !important",
                    fontSize: "50px",
                    fontFamily: "Buff",
                    textAlign: "center"
                }}>Confirmar Asistencia</Typography>
                <Box sx={{marginTop: "20px"}}>
                    <Typography sx={{
                        fontFamily: "balgin",
                        color: "black",
                        padding: "0px",
                        letterSpacing: "3px",
                        textAlign: "center",
                        fontSize: "18px"
                    }}>
                        ESPERAMOS QUE NOS PUEDAN ACOMPAÑAR 
                    </Typography>
                </Box>
                {confirm > 0 ? (<TextCarios sx={{color: "#A76C40", marginTop: "40px", fontSize: "40px"}}>Gracias</TextCarios>): (<Grid container sx={{marginTop: "40px"}}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4} sx={{
                        padding: "5px"
                    }}>
                        <Box
                            onClick={handleOpen}
                            sx={{
                                background: "#FFDBA1",
                                borderRadius: "100px",
                                padding: "12px",
                                minHeight: "70px",
                                display: "grid",
                                alignContent: "center"
                            }}
                        >
                            <TxtSubTitles sx={{
                                fontFamily: "'balgin'",
                                color: "black",
                                padding: "0px",
                                letterSpacing: "2px",
                                fontSize: "15px"
                            }}>SI, POR NADA ME LO PIERDO</TxtSubTitles>
                        </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4} sx={{
                        padding: "5px"
                    }}>
                        <Box sx={{
                            background: "#FFDBA1",
                            borderRadius: "100px",
                            padding: "12px",
                            minHeight: "70px",
                            display: "grid",
                            alignContent: "center"

                        }}>
                            <TxtSubTitles onClick={e=>aceptarAsistencia(1010)} sx={{
                                fontFamily: "'balgin'",
                                color: "black",
                                padding: "0px",
                                letterSpacing: "2px",
                                fontSize: "15px"
                            }}>NO PODRE ASISTIR, LO LAMENTO</TxtSubTitles>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>)}
                <Box sx={{padding: "20px 30px", marginTop: "20px"}}>
                    <TxtSubTitles sx={{
                        fontFamily: "'balgin'",
                        color: "black",
                        padding: "0px",
                        letterSpacing: "4px",
                        fontSize: "18px"
                    }}>
                        DA CLIC EN EL BOTÓN Y AYUDANOS CONFIRMANDO TU ASISTENCIA LO ANTES POSIBLE 
                    </TxtSubTitles>
                </Box>
                <Box sx={{position: "relative", height: "100vh", zIndex: -3, marginTop: "20px"}}>
                    <Box sx={{width: "100% !important", display: "grid", justifyItems: "center"}}>
                        <img src={img_1} width="60%" style={{maxWidth: "350px"}}/>
                    </Box>
                </Box>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    </Box>);
}

export default Confirmacion
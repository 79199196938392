import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextBuff, TextCarios, TextGlas, TxtSubTitles } from "./Textos"
import { Box, Typography } from "@mui/material"
import { Facebook, Instagram } from "@mui/icons-material";

const Obsequios = ({bgcolor}) => {
    return (<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer translateY={[16, 16]} opacity={[0.4, 1.5]}>
            <TextCarios sx={{
                color: "black",
                padding: "0px",
                marginTop: "20px !important",
                fontSize: "60px"
            }}>Recuerdos</TextCarios>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[22, 22]} opacity={[0.4, 1.5]}>
            <Box sx={{padding: "50px"}}>
                <TxtSubTitles sx={{color: "black", padding: "0px", marginTop: "20px !important", fontSize: "16px"}}>
                    COMPARTENOS Y Etiquetanos en las publicaciones EN REDES SOCIALES de MI XV AÑOS utilizaNDO EL hashtag
                </TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[65, -10]} opacity={[-0.5, 1.5]}>
            <TextBuff sx={{color: "black", padding: "0px", marginTop: "20px !important", fontSize: "45px"}}>
                #XVJEEI
            </TextBuff>
            <Box sx={{display: "grid", justifyContent: "center", marginTop: "50px", "& svg": {fontSize: "40px"}}}>
                <Box sx={{display: "flex"}}>
                    <Facebook sx={{color: "#D6BDC0"}}/>
                    <Instagram sx={{color: "#D6BDC0"}}/>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[50, 60]} opacity={[-0.5, 1.5]}>
            <Box sx={{marginTop: "130px"}}>
                <TextGlas sx={{
                    color: "white",
                    textShadow: "0px 0px 12px #ff00bf",
                    fontSize: "50px"

                }}>Celebremos</TextGlas>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[55, 80]} opacity={[-0.5, 1.5]}>
            <Box>
                <TxtSubTitles sx={{color: "black", fontWeight: "bold"}}>Juntos</TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>);
}

export default Obsequios
import '../styles/css/tmp-000-001.css';
import React, { useEffect, useState, useRef } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Grid, Typography, Box } from '@mui/material';

const Circle = React.memo(({ unity, children, color_3}) => (
  <Grid className="no-padding" sx={{ display: "grid", alignContent: "center", justifyItems: "center" }} item xl={1} lg={1} md={1.5} sm={2} xs={3}>
    <Box className="circle-banner" sx={{ background: `${color_3} !important`, margin: "0px 5px" }}>
      <Typography sx={{ color: "black", fontSize: "25px", padding: "8px", fontFamily: "'balgin'" }}>
        {unity}
      </Typography>
    </Box>
    <Box sx={{ fontSize: "18px", fontFamily: "'balgin'", letterSpacing: "1px", textTransform: "uppercase", marginTop: "20px", color: "black" }}>
      {children}
    </Box>
  </Grid>
));

const Banner = ({
  typetxt = "Evento",
  bkImage,
  bkImage2,
  deadline = "31 Dec 2024 17:30:00 UTC",
  bgcolor = "#FFF2EE",
  color_3
}) => {
  const [time, setTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  const leading0 = (num) => (num < 10 ? "0" + num : num);

  const updateTimeUntil = (date) => {
    const timeDiff = Date.parse(date) - Date.now();
    if (timeDiff < 0) return setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    setTime({
      days: Math.floor(timeDiff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeDiff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeDiff / (1000 * 60)) % 60),
      seconds: Math.floor((timeDiff / 1000) % 60),
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => updateTimeUntil(deadline), 1000);
    return () => clearInterval(intervalId);
  }, [deadline]);

  return (
    <Box className="container">
      <Box sx={{ position: "relative" }}>
        <ParallaxBanner
          style={{ height: "100vh", background: bgcolor, display: "grid", alignContent: "end" }}
          layers={[
            {
              speed: 0,
              easing: [1, 1, 1, 1],
              scale: [1, 1.2, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
            },
            {
              translateY: [6, 0],
              opacity: [3, 0.7],
              scale: [1, 1, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              children: (
                <div style={{ position: "absolute", top: "-40px", color: "black", textAlign: "center", width: "100%" }}>
                  <Typography sx={{ fontFamily: "balgin", fontSize: "35px", marginTop: "10px" }}>
                    - {typetxt.toUpperCase()} -
                  </Typography>
                </div>
              ),
            },
            {
              translateY: [4, 0],
              opacity: [3, 0.7],
              scale: [1, 1, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              children: (
                <div style={{ position: "absolute", top: "50px", textAlign: "center", width: "100%" }}>
                  <Box sx={{ height: "150px", width: "100%", display: "grid", justifyItems: "center" }}>
                    <img src={bkImage} alt="Background Image" width="80%" />
                  </Box>
                  <Box sx={{ marginTop: "110px" }}>
                    <p style={{ fontFamily: "'balgin'", color: "black", textTransform: "uppercase", fontSize: "20px", marginBottom: "15px" }}>
                      ¿Cuánto Falta?
                    </p>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Circle color_3={color_3} unity={leading0(time.days)}>días</Circle>
                    <Circle color_3={color_3} unity={leading0(time.hours)}>horas</Circle>
                    <Circle color_3={color_3} unity={leading0(time.minutes)}>min</Circle>
                    <Circle color_3={color_3} unity={leading0(time.seconds)}>seg</Circle>
                  </Box>
                </div>
              ),
            },
          ]}
        />
        <Box sx={{ position: "absolute", bottom: "0px", left: 0 }}>
          <img src={bkImage2} alt="Secondary Background" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;

import { Box } from "@mui/material";
import { ParallaxBanner, ParallaxBannerLayer, useParallax } from "react-scroll-parallax";
const App = ({images = [], bgcolor}) => {
  // const parallaxRotateY = useParallax({
  //   translateY: [0, -1]
  // });

  const phrases = [
    {
      text: '"¿Quién es la que resplandece como el amanecer,tan hermosa como la luna llena, tan pura como la luz del sol?"',
      versiculo: "CANT. 6:10"
    },
    {
      text: '“A donde tú vayas yo iré, y donde tú pases la noche yo pasaré la noche. Tu pueblo será mi pueblo, y tu Dios será mi Dios”',
      versiculo: "RUT 1:16"
    }
  ]
  
  // const parallaxEasingLeft = useParallax({
  //   translateY: [0, 80],
  //   speed: 20
  // });

  return (<>
    {images?.length > 0 ? images.map((bkImage, key_phrase) => {
      return(<ParallaxBanner key={key_phrase} className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
      <ParallaxBannerLayer className="contain-cover" image={bkImage.value} speed={-5} />
    <ParallaxBannerLayer translateY={[13, 48]} speed={-5} scale={[0.99, 1]}>
        <p style={{
            letterSpacing: "4px",
            fontSize: "20px",
            width: "100%",
            textAlign: "center",
            padding: "0px 20px",
            lineHeight: "19px",
            fontFamily: "bright",
            color: "white"
        }}>{phrases[key_phrase].text}</p>
        <p style={{
            letterSpacing: "3px",
            fontSize: "16px",
            width: "100%",
            color: "white",
            textAlign: "end",
            padding: "8px",
            zIndex: 2,
            fontFamily: "bright"
        }}>({phrases[key_phrase].versiculo})</p>
    </ParallaxBannerLayer>
  </ParallaxBanner>)}): null}
  </>);
};
export default App;
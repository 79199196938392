import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { Add, ArrowForwardIos, Close, Remove } from "@mui/icons-material";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { TextSouthwalles } from "./Obsequios";
import { Auth } from "../../context";

const Confirmacion = ({bkImage, guest, evento, open, setOpen, bgcolor, color}) => {
        const authContext = useContext(Auth);
        const { confirmarAsistencia, confirmado } = authContext;
        const handleOpen = () => { setOpen(true) };
        const handleClose = () => { setOpen(false) };
        const [confirm, setConfirm] = useState(0);
        const [asistidos, setAsistidos] = useState(0);
      
        const aceptarAsistencia = (noAsistire = false) => {
          confirmarAsistencia(noAsistire ? 1010: asistidos)
          handleClose()
        }
      
        const onChange = e => {
          if(e?.target?.value && e?.target?.value > 0 && guest?.reservaciones){
            if(e?.target?.value <= guest?.reservaciones){
              setAsistidos(e.target.value)
            }
          } 
        }
      
        useEffect(()=>{
            if(guest)setConfirm(confirmado ? true: false)
        },[confirmado])
      
        useEffect(()=> {
          if(guest){
            setConfirm(guest?.confirm ? guest.confirm: 0)
            setAsistidos(guest?.reservaciones ? guest.reservaciones: 0)
          }  
        },[guest])
    
    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        borderRadius: "8px",
        p: 2
    };

    const TxtTitle = styled('p')({
        color: "black",
        fontFamily: "'Antic Didone'",
        fontWeight: "bold",
        fontSize: "18px"
    })
    
    const TxtSub = styled('p')({
        color: "black",
        fontSize: "15px",
        fontFamily: "'Antic Didone'"
    })

    return (<Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className='test-kdemslkamds'
            sx={{
              display: "grid",
              alignContent: "center",
              justifyContent: "center"
            }}
        >
            <Box sx={{outline: "0px !important", ...style}}>
                <Close onClick={handleClose} sx={{
                    cursor: "pointer",
                    color: "black !important",
                    position: "absolute",
                    top: "10px",
                    right: "10px"
                }}/>
                <TxtTitle sx={{textAlign: "center"}}>¿Deseas confirmar tu asistencia?</TxtTitle>
                <TxtSub sx={{marginTop: "10px"}}>
                    Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
                </TxtSub>
                <Grid container sx={{marginTop: "20px"}}>
                    <Grid item xs={7}>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <Button onClick={()=>{
                                    if(asistidos && asistidos > 0){
                                        if(asistidos < guest?.reservaciones){
                                            setAsistidos(asistidos+1)
                                        }
                                    } 
                                }}><Add sx={{color: "white !important", background: "black"}}/></Button>
                            </Grid>
                            <Grid item xs={8} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <TextField
                                    variant='standard'
                                    type='hidden'
                                    value={asistidos}
                                    onChange={onChange}
                                    // sx={{"& input":{color: "black !important", textAlign: "center", fontSize: "12px"}}}
                                />
                                <TxtSub sx={{fontWeight: "bold"}}>
                                    {asistidos} persona{asistidos > 1? "s": ""}
                                </TxtSub>
                            </Grid>
                            <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <Button onClick={()=>{
                                    if(asistidos && (asistidos-1) > 0 && guest?.reservaciones){
                                        setAsistidos(asistidos-1)
                                    } 
                                }}>
                                    <Remove sx={{color: "white !important", background: "black"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                        <TxtSub
                            onClick={() => aceptarAsistencia()}
                            sx={{
                                color: "white",
                                background: "black",
                                textAlign: "center",
                                padding: "7px 5px",
                                borderRadius: "5px"
                        }}>Aceptar</TxtSub>
                    </Grid> 
                </Grid>
            </Box>
        </Modal>
        <ParallaxBanner style={{ height: "95vh", aspectRatio: '2 / 1', background: bgcolor }}>
          <ParallaxBannerLayer className="contain-cover-tiny" image={bkImage} speed={-5} />
            <ParallaxBannerLayer translateY={[14, 10]} opacity={[0.4, 1.5]}>
                <TextSouthwalles sx={{
                    fontSize: "50px",
                    color: "white",
                    fontWeight: "bold",
                    letterSpacing: "0px",
                    fontFamily: "brittany"
                }}>Confirma tu asistencia</TextSouthwalles>
            </ParallaxBannerLayer>
                {confirm > 0 ? (<ParallaxBannerLayer translateY={[52, 81]} opacity={[0.4, 1.5]}>
                    <Typography sx={{
                        marginTop: "50px",
                        fontSize: "60px",
                        textAlign: "center",
                        textShadow: "0px 0px 10px white",
                        fontFamily: "brittany"
                    }}>Gracias</Typography>
                </ParallaxBannerLayer>): 
                (<ParallaxBannerLayer translateY={[52, 65]} opacity={[0.4, 1.5]}><Grid container sx={{marginTop: "60px", padding: "20px"}}>
                    <Grid item xs={12} sx={{ padding: "5px", justifyContent: "center", display: "grid"}}>
                        <Box
                            onClick={handleOpen}
                            sx={{
                                background: bgcolor,
                                borderRadius: "50px",
                                padding: "12px",
                                minHeight: "30px",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                          <Typography sx={{
                              fontSize: "16px",
                              color: "black",
                              padding: "0px",
                              lineHeight: "15px",
                              fontFamily: "bright",
                              textAlign: "center",
                              marginRight: "10px"
                          }}>SI ASISTIRÉ, POR NADA ME LO PIERDO</Typography>
                          <Box sx={{
                            background: color,
                            height: "30px",
                            width: "30px",
                            borderRadius: "50px",
                            display: "grid",
                            alignContent: "center",
                            justifyContent: "center"
                          }}>
                            <ArrowForwardIos sx={{fontSize: "20px"}}/>
                          </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: "5px", justifyContent: "center", display: "grid"}}>
                    <Box
                            onClick={e=> aceptarAsistencia(1010)}
                            sx={{
                                background: bgcolor,
                                borderRadius: "50px",
                                padding: "12px",
                                minHeight: "30px",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                          <Typography sx={{
                              fontSize: "16px",
                              color: "black",
                              padding: "0px",
                              lineHeight: "15px",
                              fontFamily: "bright",
                              textAlign: "center", 
                              marginRight: "10px"
                          }}>NO PODRE ASISTIR, LO LAMENTO</Typography>
                          <Box sx={{
                            background: color,
                            height: "30px",
                            width: "30px",
                            borderRadius: "50px",
                            display: "grid",
                            alignContent: "center",
                            justifyContent: "center"
                          }}>
                            <ArrowForwardIos sx={{fontSize: "20px"}}/>
                          </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid></ParallaxBannerLayer>)}
            
        </ParallaxBanner>
        <ParallaxBanner style={{ height: "5vh", aspectRatio: '2 / 1', background: "white" }}>
          <Box sx={{
            display: "grid",
            alignContent: "center",
            justifyContent: "center"
          }}>
            <Typography sx={{
              fontFamily: "bright",
              color: "black",
              fontSize: "18px",
              textAlign: "center"
            }}>AYUDANOS CONFIRMANDO LO ANTES POSIBLE</Typography>
          </Box>
        </ParallaxBanner>
    </Box>);
}

export default Confirmacion
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TxtSubTitles } from "./Textos"
import { Box, Grid } from "@mui/material"
import img_1 from "./assets/marcos.png"
import hotel_op4 from '../../images/sobre-removebg-preview.png'
import hotel_op5 from './assets/obsequios_regalo.png'

const Obsequios = ({bgcolor="#FFF2EE", bgImage, color_2, txtObsequios}) => {
    return (<ParallaxBanner style={{ height: "70vh", aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer opacity={[-1, 3.5]} translateY={[0, 0]}>
            <Box sx={{position: "absolute", top: 0, left: 0, width: "100%"}}>
                <img src={bgImage} width="100%"/>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[23, 19]} opacity={[-1, 3.5]}>
            <TextCarios sx={{
                color: color_2,
                padding: "0px",
                marginTop: "20px !important",
                fontSize: "70px"
            }}>Obsequios</TextCarios>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[34, 22]} opacity={[-1, 2.5]}>
            <Box sx={{padding: "50px"}}>
                <TxtSubTitles sx={{
                    color: "black",
                    padding: "0px",
                    marginTop: "20px !important",
                    fontSize: "20px",
                    fontFamily: "'balgin'",
                    letterSpacing: "4px",
                    lineHeight: "25px"
                }}>{txtObsequios}
                </TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[55, 50]} opacity={[-1, 3.5]}>
            <Box sx={{height: "40vh", width: "100%", padding: "1vh", textAlign: "center"}}>
                <Grid container sx={{padding: "40px"}}>
                    <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                        <img src={hotel_op5} style={{width: "100%", maxWidth: "320px", maxHeight: "300px"}}/>
                    </Grid>
                    <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                        <img src={hotel_op4} style={{width: "100%", maxWidth: "320px", maxHeight: "300px"}}/>
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>);
}

export default Obsequios
import { Box, styled } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TxtSubTitles } from "./Textos"
import { CalendarMonth, Place, Schedule } from "@mui/icons-material"

const VerMapaBtn = styled('div')({
    color: "black",
    background: "#f0e2ca",
    width: "fit-content",
    padding: "5px",
    borderRadius: "20px",
    marginTop: "25px",
    minWidth: "150px"
})

const Recepcion = ({bkImage, bgcolor, direccion, salon, hora_salon, maps_salon, fechaEvento}) => {
    let dir = direccion ? direccion: "Av Luis Donaldo Colosio No. 110 Ciudad Solidaridad"
    let nombre_dir  = salon ? salon: 'La amatista eventos'
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor}}>
            <ParallaxBannerLayer translateY={[0, 0]} opacity={[0.4, 1.5]}>
                <Box sx={{height: "40vh", width: "100%", padding: "1vh"}}>
                    <img src={bkImage} style={{width: "100%", height: "38vh"}}/>
                </Box>
                <TextCarios sx={{color: "black", padding: "0px", marginTop: "20px !important", fontSize: "50px"}}>
                    Recepción
                </TextCarios>
                <TxtSubTitles sx={{color: "black", padding: "0px", fontSize: "15px", fontWeight: "bold"}}>
                    {nombre_dir}
                </TxtSubTitles>
                <Box sx={{marginTop: "60px"}}>
                    <Box sx={{marginTop: "30px"}}>
                        <Box sx={{display: "block", padding: "0px 50px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "12px",
                                padding: "0px",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                            }}><Place sx={{color: "#B46490", fontSize: "24px"}}/>{dir}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "30px"}}>
                        <Box sx={{display: "block", padding: "0px 30px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "12px",
                                padding: "0px",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center"
                            }}><CalendarMonth sx={{color: "#B46490", fontSize: "24px", marginRight: "15px"}}/>{fechaEvento ? fechaEvento: "SÁBADO, 17 DE AGOSTO 2024"}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "30px"}}>
                        <Box sx={{display: "block", padding: "0px 30px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "12px",
                                padding: "0px",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center"
                            }}><Schedule sx={{color: "#B46490", fontSize: "24px", marginRight: "15px"}}/>{hora_salon ? hora_salon: "7:00 P.M."}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{display: "grid", justifyContent: "center"}}>
                        <VerMapaBtn>
                            <TxtSubTitles onClick={e=>{
                                let map_dir = maps_salon ? maps_salon: "https://www.google.com/maps/place/Eventos+%7C+Bodas+y+XV+a%C3%B1os+%7C+Monterrey+%7C+La+Amatista+Eventos/@25.7626686,-100.4064912,17z/data=!3m1!4b1!4m6!3m5!1s0x866290d2cfaa01c1:0x2ad3b22391485632!8m2!3d25.7626686!4d-100.4039163!16s%2Fg%2F11c703qfn_?entry=ttu"
                                var win = window.open(map_dir, '_blank');
                                // Cambiar el foco al nuevo tab (punto opcional)
                                win.focus();
                            }} sx={{fontSize: "15px", fontWeight: "bold", padding: "5px"}}>VER MAPA</TxtSubTitles>
                        </VerMapaBtn>
                    </Box>
                </Box>
            </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

export default Recepcion
import React, {useReducer} from 'react'
import authReducer from './reducer'
import {Auth as AuthContext} from '../index'
import clientAxios  from '../../config/axios'
import tokenAuth from '../../config/tokenAuth'

import {
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    REGISTRO_ERROR_MSG,
    AGREGAR_TOKEN,
    OBTENER_INVITADO,
    CONFIRMAR_INVITACION
} from '../../types'

const Auth = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        informacion: null,
        mensaje: null,
        loading: true,
        confirmado: false
    }

    const [ state, dispatch ] = useReducer(authReducer, initialState)

    const registrarUsuario = async data => {
        try{
            const result = await clientAxios.post('/api/users', data)
            dispatch({
                type: REGISTRO_EXITOSO,
                payload: result.data
            })

            usuarioAutenticado()
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const usuarioAutenticado = async reqtoken => {
        try{
            const token = await reqtoken;
            
            if(token){
                // TODO: funcion mandar token por headers
                tokenAuth(token);
            }
            
            const result = await clientAxios.get('/api/auth/code'), result_data = result?.data ? result.data: null;
            if(result_data?.invitado){
                dispatch({
                    type: OBTENER_USUARIO,
                    payload: result_data.invitado
                })
            }
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alert
            })
        }
    }

    const setToken = async token => {
        if(token){
            dispatch({
                type: AGREGAR_TOKEN,
                payload: token
            })
        }
    }

    const login = async code => {
        try{
            const result = await clientAxios.post('/api/auth/code', {code: code}), result_data = result?.data ? result?.data: null;   
            
            if(result_data?.token){
                dispatch({
                    type: LOGIN_EXITOSO,
                    payload: result.data
                })
                usuarioAutenticado(result_data.token)
            }else{
                dispatch({
                    type: REGISTRO_ERROR_MSG,
                    payload: "Error"
                })
            }
            
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR_MSG,
                payload: alert
            })
        }
    }

    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    const authInvitado = async () => {
        const token = localStorage.getItem('token');
        if(token){
            // TODO: funcion mandar token por headers
            tokenAuth(token);
        }

        try{
            const result = await clientAxios.get('/api/auth/code');
            if(result?.data){
                dispatch({
                    type: OBTENER_INVITADO,
                    payload: result.data
                })
            }

        } catch(err){
            const alert = {
                msg: err?.response?.data?.msg ? err.response.data.msg: err,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const confirmarAsistencia = async (asistencia) => {
        console.log(asistencia)
        if(asistencia){
            const result = await clientAxios.post('/api/guests/confirm', {confirm: asistencia})
            if(result?.data){
                let data = result.data
                if(data?.code == 200){
                    dispatch({
                        type: CONFIRMAR_INVITACION,
                        payload: true
                    })
                }
            }
        }
    }

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                loading: state.loading,
                invitado: state.invitado,
                informacion: state.informacion,
                confirmado: state.confirmado,
                setToken,
                registrarUsuario,
                usuarioAutenticado,
                login,
                authInvitado,
                confirmarAsistencia,
                cerrarSesion
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default Auth;
import { Box, styled } from "@mui/material"
import corner_up from "./assets/icono_florarriba.png"
import corner_down from "./assets/icono_florabajo.png"
import { TxtSubTitles } from "./Textos"

const Frases = ({bgcolor}) => {
    return(<Box sx={{
        height: "40vh",
        background: bgcolor,
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        position: "relative"
    }}>
        <Box sx={{position: "absolute", top: 5, left: 5}}><img src={corner_up} width="120px"/></Box>
        <Box sx={{position: "absolute", bottom: 5, right: 5}}><img src={corner_down} width="120px"/></Box>
        <TxtSubTitles>"hoy brindo por tenerte aqui, por verte sonreir"</TxtSubTitles>
        
    </Box>)
}

export default Frases;
import { useEffect } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TextPlannet } from "."
import { Box, Typography } from "@mui/material"
import { TextSouthwalles } from "./Obsequios"

const Celebremos = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
    <ParallaxBannerLayer image={bkImage} />
    <ParallaxBannerLayer  translateY={[45, -15]}>
        <Typography style={{
            letterSpacing: "4px",
            fontSize: "20px",
            width: "100%",
            textAlign: "center",
            padding: "0px 20px",
            lineHeight: "19px",
            fontFamily: "bright",
            color: "white"
        }}>
            "Llévame contigo; corramos. Estemos felices y alegrémonos juntos" (Cant. 1:4)
        </Typography>
    </ParallaxBannerLayer>
    
    <ParallaxBannerLayer  translateY={[58, 71]}>
        <Box sx={{display: "grid", justifyContent: "center"}}>
            <TextSouthwalles sx={{
                fontSize: "65px",
                letterSpacing: "1px",
                lineHeight: "75px",
                color: "white",
                fontFamily: "brittany"
            }}>Celebremos</TextSouthwalles>
            <Typography sx={{
                fontSize: "45px",
                color: "white",
                fontFamily: "bright",
                textAlign: "center",
                textShadow: "0px 6px 15px white"
            }}>Juntos</Typography>
        </Box>
    </ParallaxBannerLayer>
    </ParallaxBanner>)
}

export default Celebremos
import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Pause, PlayArrow } from '@mui/icons-material';
import audioFile from '../../audio/tmp_evento_1.mp3';
import panel from "./assets/button_play_dino.png";

const PlayButton = ({bkImage, color_4}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showNotification, setShowNotification] = useState(true);
    const audioRef = useRef(new Audio(audioFile));

    const togglePlay = () => {
        setIsPlaying(prev => !prev);
        isPlaying ? audioRef.current.pause() : audioRef.current.play();
    };

    useEffect(() => {
        const timer = setTimeout(() => setShowNotification(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('ended', () => setIsPlaying(false));
        return () => audio.removeEventListener('ended', () => setIsPlaying(false));
    }, []);

    return (
        <Box sx={{ position: "sticky", top: "7rem", zIndex: 15, cursor: "pointer", display: "grid", justifyContent: "right" }} onClick={togglePlay}>
            {showNotification && (
                <Box sx={{
                    position: "absolute", top: 35, right: 32, backgroundColor: "white",
                    padding: "5px", borderRadius: "10px", boxShadow: "0px 0px 10px -2px black",
                    zIndex: 2
                }}>
                    <p style={{color: "black", fontSize: "11px"}}>Toca para reproducir</p>
                </Box>
            )}
            <Box sx={{ position: "relative", height: "50px" }}>
                <Box sx={{position: "absolute", display: "grid", width: "100%", height: "50px", alignContent: "center", justifyContent: "center"}}>
                    {isPlaying ? <Pause sx={{ color: color_4 }} /> : <PlayArrow sx={{ color: color_4 }} />}
                </Box>
                <img src={bkImage ? bkImage: panel} height="50px" alt="Play button" />
            </Box>
        </Box>
    );
};

export default PlayButton;

import { Box } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TxtSubTitles } from "./Textos"
import traje from './assets/traje.png'
import vestido from './assets/vestido.png'

const Dresscode = ({bkImage, bgcolor}) => {
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor}}>
            <ParallaxBannerLayer translateY={[0, 0]} opacity={[0.4, 1.5]}>
                <Box sx={{height: "40vh", width: "100%", padding: "1vh", position: "relative"}}>
                    <img src={bkImage} style={{width: "100%", height: "38vh"}}/>
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        textAlign: "center"
                    }}>
                        <TextCarios sx={{
                            color: "white",
                            fontSize: "65px",
                            textShadow:"1px 0px 12px #212121"
                        }}>Dresscode</TextCarios>
                    </Box>
                </Box>
                <TxtSubTitles sx={{color: "black", padding: "0px", marginTop: "20px !important", fontSize: "30px"}}>
                    Formal
                </TxtSubTitles>
                <Box>
                    <Box sx={{
                        height: "42vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Box sx={{padding: "0px 20px"}}>
                            <img style={{height: "28vh"}} src={traje}/>
                            <TxtSubTitles sx={{color: "black", fontSize: "15px"}}>Hombres</TxtSubTitles>
                        </Box>
                        <Box>
                            <img style={{height: "28vh"}} src={vestido}/>
                            <TxtSubTitles sx={{color: "black", fontSize: "15px"}}>Mujeres</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "30px"}}>
                    </Box>
                </Box>
            </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

export default Dresscode
import { Box, styled, Typography } from "@mui/material"
import img_1 from "./assets/marcos.png"
import { TxtSubTitles } from "./Textos"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

const Consideraciones = ({bgcolor, bkImage, txtConsi1 = "", txtConsi2 = "", color_2, line_bottom}) => {
    return(<ParallaxBanner style={{ height: "100vh", aspectRatio: '2 / 1', background: bgcolor, position: "relative"}}>
        <ParallaxBannerLayer opacity={[-1, 3.5]} translateY={[0, 0]}>
            <Typography sx={{ color: color_2, padding: "0px", marginTop: "1.25rem !important", fontSize: "3.125rem", fontFamily: "Buff", textAlign: "center" }}>Consideraciones</Typography>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[20, 25]}>
            <Typography sx={{
                textAlign: "center",
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "1.3rem !important",
                letterSpacing: "4px",
                padding: "8% 12%",
                lineHeight: 1.3
            }}>{txtConsi1 ? txtConsi1: `DEFAULT`
            }</Typography>
            <Box sx={{width: "100%", display: "grid", justifyContent: "center", paddingTop: "30px"}}>
                {bkImage ? (<img src={bkImage} style={{maxHeight: "8rem", minHeight: "8rem"}}/>):null}
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[45, 60]}>
            <Typography sx={{
                textAlign: "center",
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "1.3rem !important",
                letterSpacing: "4px",
                padding: "8% 12%",
                lineHeight: 1.3
            }}>{txtConsi2 ? txtConsi2: `DEFAULT`
            }</Typography>
        </ParallaxBannerLayer>
        <Box sx={{position: "absolute", bottom: 0, left: 0, width: "100%"}}>
            {line_bottom ? (<img src={line_bottom} style={{width: "100%", rotate: "180deg"}}/>):null}
        </Box>
    </ParallaxBanner>)
}

export default Consideraciones;
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { Box, Typography } from "@mui/material"
import { Facebook, Instagram } from "@mui/icons-material";
import { TextSouthwalles } from "./Obsequios";

const Obsequios = ({hashtag = "hashtag", bgcolor, color}) => {
    return (<ParallaxBanner style={{ height: "60vh", aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer translateY={[20, 50]} opacity={[0.4, 1.5]}>
            <Typography sx={{
                fontSize: "65px",
                color: "black",
                fontFamily: "brittany",
                textAlign: "center"
            }}>Recuerdos</Typography>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[32, 50]} opacity={[0.4, 1.5]}>
            <Box sx={{padding: "50px"}}>
                <Typography sx={{
                    color: "black",
                    padding: "0px",
                    marginTop: "20px !important",
                    fontSize: "16px",
                    fontFamily: "belleza_reg",
                    textAlign: "center"
                }}>
                    COMPARTE Y ETIQUETANOS EN LAS
                    PUBLICACIONES EN REDES SOCIALES DE
                    NUESTRA BODA UTILIZANDO EL HASHTAG
                </Typography>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[47, 62]} opacity={[-0.5, 1.5]}>
            <Typography sx={{
                color: "black",
                padding: "0px",
                marginTop: "20px !important",
                fontSize: "45px",
                textAlign: "center",
                fontFamily: "brittany"
            }}>
                #{hashtag}
            </Typography>
            <Box sx={{display: "grid", justifyContent: "center", marginTop: "50px", "& svg": {fontSize: "40px"}}}>
                <Box sx={{display: "flex"}}>
                    <Facebook sx={{color: color}}/>
                    <Instagram sx={{color: color}}/>
                </Box>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>);
}

export default Obsequios
import { Box, Typography } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextAdega, TextCarios, TextRobotoReg, TextSouthwalles } from "."

const Consideraciones = ({bkImage}) => {
    return(<ParallaxBanner style={{ height: "55vh", aspectRatio: '2 / 1', background: "#d4dec4" }}>
        <ParallaxBannerLayer scaleX={[0.99, 1]} image={bkImage} speed={-5} />
        <ParallaxBannerLayer translateY={[8, 8]} opacity={[-0.5, 2.5]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextSouthwalles sx={{
                    fontSize: "105px",
                    color: "black",
                }}>Consideraciones</TextSouthwalles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[-20, -30]} opacity={[-0.5, 2.5]}>
            <Box sx={{
                position: "absolute",
                bottom: "15px",
                width: "100%",
                zIndex: "2",
            }}>
                <Box>
                    <Typography sx={{
                        fontSize: "20px",
                        color: "black",
                        padding: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        lineHeight: "25px",
                        fontFamily: "bright",
                    }}>
                        Amamos mucho a sus hijos pequeños pero este
                        evento está diseñado solo para adultos.
                    </Typography>
                    <Typography sx={{
                        fontSize: "20px",
                        color: "black",
                        padding: "0px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        // letterSpacing: "4px",
                        lineHeight: "25px",
                        fontFamily: "bright",
                    }}>
                        Agradecemos su apoyo a este respecto.
                    </Typography>
                    <Typography sx={{
                        fontSize: "30px",
                        color: "black",
                        padding: "0px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: "40px",
                        lineHeight: "25px",
                        fontFamily: "flower_1",
                    }}>
                        ________B________
                    </Typography>
                </Box>
            </Box>
        </ParallaxBannerLayer>

    </ParallaxBanner>)
}

export default Consideraciones
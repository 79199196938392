import { Box, Typography } from "@mui/material"
import { BloqueAnimacion, Seccion, TextAdega, TextAmazing, TextPlanner, TextRoboto, TextSouthwalles } from "."
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useEffect, useState } from "react"
import { formatInputDate } from "../../elementos/formatDate"

const Invitado = ({bkImage, invitado, bgcolor}) => {
    const [nombre, setNombre] = useState("")
    const [reservaciones, setReservacion] = useState(0)
    useEffect(()=>{
        console.log(invitado)
        if(invitado?.nombre){
            setNombre(invitado.nombre)
        }
        setReservacion(invitado.reservaciones)
    },[invitado])


    let phrase = "Nos encantaría que seas parte de este momento tan especial para nosotros"
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <Box sx={{
            "& .contain-cover":{
          backgroundSize: "68vh !important"
        }
        }}>
            <ParallaxBannerLayer className="contain-cover" image={bkImage} speed={-5} />
        </Box>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[2, 2]} translateX={[30, -30]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "20px"}}>
                <Typography sx={{
                    color: "white",
                    fontFamily: "bright",
                    textAlign: "center",
                    fontSize: "20px !important"
                }}>{phrase.toUpperCase()}</Typography>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[18, 18]} translateX={[-30, 30]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
                {/* <TextPlanner sx={{fontSize: "33px !important", marginBottom: "15px"}}>¡ACOMPAÑANOS!</TextPlanner> */}
                <Box sx={{
                    maxWidth: "280px",
                    minWidth: "280px",
                    padding: "0px 20px",
                    border: "2px solid #f7f3ed"
                }}>
                    <Typography sx={{
                        fontSize: "22px !important",
                        fontFamily: "belleza_reg",
                        textAlign: "center",
                        height: "25px",
                        color: "#f7f3ed"
                    }}>{nombre.toUpperCase()}</Typography>
                    <TextRoboto sx={{
                        fontSize: "26px !important",
                        color: "#f7f3ed"
                    }}>{`(${reservaciones})`}</TextRoboto>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            bottom: "20px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRoboto sx={{
                    fontSize: "42px !important",
                    color: "white",
                    fontWeight: "bold"
                }}>{formatInputDate(invitado?.fecha ? invitado.fecha: Date.now() )}</TextRoboto>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Invitado
import { Box } from "@mui/material"
import Banner from './Banner'
import { useContext, useEffect, useState } from "react"
import { Multimedia } from "../../context";
import { extract_file } from "../../functions";
import Invitado from "./Invitado";
import Recepcion from "./Recepcion";
import Obsequios from "./Obsequios";
import Confirmation from "./Confirmacion";
import Recuerdos from "./Recuerdos";
import Galeria from "./Galeria";
import PlayButton from "./Playbutton";
import Frases from "./Frases";
import { Footer } from "../../componentes";
import { formatInputDateStr } from "../../elementos/formatDate";
import Consideraciones from "./Consideraciones";

const Component = (data = []) => {
    const multimediaContext = useContext(Multimedia);
    const { obtener_filename, multimedia } = multimediaContext;

    const [secciones,   setSecciones]   = useState([])
    const [multimedia_, setMultimedia_] = useState([])
    const [evento, setEvento]           = useState({})
    const [padre, setPadre]             = useState("")
    const [madre, setMadre]             = useState("")
    const [invitado, setInvitado]       = useState({})
    const [fecha,  setFecha]            = useState("31 Mar 2024 17:30:00 UTC")
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        let evento = null
        if(data?.data?.evento)   evento = data.data.evento
        if(data?.data?.invitado) setInvitado(data.data.invitado)
        if(evento && evento?.apartados){
            setSecciones(evento.apartados)
            obtener_filename(evento._id)
            setEvento(evento)

            if(evento?.dtEvento){
                let fecha_epoch = evento?.dtEvento ? evento.dtEvento: null
                if(fecha_epoch){
                    var d = new Date(fecha_epoch); // The 0 there is the key, which sets the date to the epoch
                    setFecha(d)
                }
            }
        }
    },[data])

    useEffect(()=>{
        if(evento?.padres?.madre){setMadre(evento.padres.madre)}
        if(evento?.padres?.padre){setPadre(evento.padres.padre)}
    },[evento])

    useEffect(()=>{
        let result = {}
        if(multimedia.length > 0){
            multimedia.map((m_, m_i) => {
                if(m_?.s_filename){
                    let file_ = extract_file(m_, multimedia)

                    result = {
                        ...result,
                        ...file_
                    }
                }

            })
        }

        setMultimedia_({...result})


    },[multimedia])

    return(<Box id="main-all-container">
        <Box className="area-container-grid">
            {secciones["*button_play"] ? (<PlayButton
                bkImage={multimedia_?.button_play ? multimedia_.button_play: null}
                color_4={evento?.color_4 ? evento.color_4: "#a76c40"}
            />): null}
            {secciones?.banner          ? (<Banner
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                color_3={evento?.color_3 ? evento.color_3: "#a76c40"}
                typetxt={evento?.titleBanner ? evento.titleBanner: "Mi primer cumpleaños"}
                nombre_corto={evento?.nombre ? evento.nombre: ""}
                bkImage={multimedia_?.banner ? multimedia_?.banner: null}
                bkImage2={multimedia_?.banner2 ? multimedia_?.banner2: null}
                data={data}
                deadline={fecha}
            />): null}
            {secciones?.frases          ? (<Frases
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                madre={madre}
                padre={padre}
                descFrases={evento?.txtFrase ? evento.txtFrase: ""}
                txtVersiculo={evento?.txtVersiculo ? evento.txtVersiculo: ""}
                bkImage={multimedia_?.frases ? multimedia_?.frases: null}
            />): null}
            {secciones?.invitado        ? (<Invitado
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                color_2={evento?.color_2 ? evento.color_2: "#a76c40"}
                evento={evento}
                txtInvitado={evento?.txtInvitado ? evento.txtInvitado: ""}
                invitado={invitado}
                bkImage={multimedia_?.invitados ? multimedia_.invitados: null}
                bgImage={multimedia_?.banner_invitado ? multimedia_.banner_invitado: null}
            />): null}
            {secciones?.recepcion       ? (<Recepcion
                color_2={evento?.color_2 ? evento.color_2: "#a76c40"}
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                bkImage={multimedia_?.recepcion ? multimedia_.recepcion: null}
                recepcion_2={multimedia_?.recepcion_2 ? multimedia_.recepcion_2: ""}
                hora_salon={evento?.hora_salon ? evento.hora_salon: null}
                maps_salon={evento?.maps_salon ? evento.maps_salon: null}
                fechaEvento={formatInputDateStr(evento.dtEvento)}
                direccion={evento?.dir_salon ? evento.dir_salon: null}
            />): null}
                {secciones?.obsequios       ? (<Obsequios
                    color_2={evento?.color_2 ? evento.color_2: "#a76c40"}
                    bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                    txtObsequios={evento?.txtObsequios ? evento.txtObsequios: ""}
                    bgImage={multimedia_?.banderilla ? multimedia_.banderilla: "default"}
                />): null}
            {secciones?.confirmacion    ? (<Confirmation
                color_2={evento?.color_2 ? evento.color_2: "#a76c40"}
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                bkImage={multimedia_?.confirmacion ? multimedia_.confirmacion: null}
                bgImage={multimedia_?.banner_confirmacion ? multimedia_.banner_confirmacion: null}
                guest={invitado}
                open={open}
                setOpen={setOpen}
                evento={evento}
                txt_1={evento?.txtConfirmacion1 ? evento.txtConfirmacion1: null}
                txt_2={evento?.txtConfirmacion2 ? evento.txtConfirmacion2: null}
                btn_1={evento?.btnConfirmacion1 ? evento.btnConfirmacion1: null}
                btn_2={evento?.btnConfirmacion2 ? evento.btnConfirmacion2: null}
            />): null}
            {secciones?.consideraciones ? (<Consideraciones
                color_2={evento?.color_2 ? evento.color_2: "#a76c40"}
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                txtConsi1={evento?.txtConsi1 ? evento.txtConsi1: "Default"}
                txtConsi2={evento?.txtConsi2 ? evento.txtConsi2: "Default"}
                bkImage={multimedia_?.consideraciones ? multimedia_.consideraciones: null}
                line_bottom={multimedia_?.banderilla ? multimedia_.banderilla: "default"}
            />): null}
            {secciones?.recuerdos       ? (<Recuerdos
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                bgImage={multimedia_?.recuerdos ? multimedia_.recuerdos: null}
                txtRecuerdos={evento?.txtRecuerdos ? evento.txtRecuerdos: "Default"}
                hashtag={data?.data?.hashtag ? data?.data?.hashtag: "#Default"}
                color_2={evento?.color_2 ? evento.color_2: "#a76c40"}
            />): null}
            {secciones?.galeria         ? (<Galeria
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                bkImage={multimedia_?.galeria ? multimedia_.galeria: null}
                color_4={evento?.color_5 ? evento.color_5: "#a76c40"}
            />): null}
            <Footer/>
        </Box>
    </Box>)
}

export default Component
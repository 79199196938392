import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Login from './pages/Login';
import Auth from './context/auth/states';
import tokenAuth from './config/tokenAuth';
import Template from './plantillas';
import MultimediaState from './context/multimedia/state';

const token = localStorage.getItem('token');
if(token){
  tokenAuth(token);
}

function App() {
  return (<Auth>
    <MultimediaState>
      <Router>
        <Routes>
          <Route exact path="/login"                    Component={Login}/>
          <Route exact path="/login/:code"              Component={Login}/>
          <Route exact path="/login/m/:code_manual"     Component={Login}/>
          <Route exact path="/invitaciones/:festejado"  Component={Template}/>
          <Route path="/*" element={<Navigate replace to="/invitaciones/access" />} />
        </Routes>
      </Router>
    </MultimediaState>
  </Auth>
  );
}

export default App;

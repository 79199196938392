import { Box, styled, Typography } from "@mui/material"
import img_1 from "./assets/marcos.png"
import { TxtSubTitles } from "./Textos"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

const Frases = ({padre, madre, bgcolor="#FFF2EE", descFrases = "", bkImage = null, txtVersiculo}) => {
    return(<ParallaxBanner style={{ height: "80vh", aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer opacity={[-1, 3.5]} translateY={[20, 20]}>
            <Box sx={{position: "absolute", top: 0, left: 0, width: "100%"}}>
                {bkImage ? (<img src={img_1} width="100%"/>):null}
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[25, 40]}>
            <Typography sx={{
                textAlign: "center",
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "22px !important",
                letterSpacing: "4px",
                padding: "8% 12%",
                lineHeight: 1.3
            }}>{descFrases ? descFrases:
                `YO ESTARÉ CONTIGO Y NO TE ABANDONARÉ HASTA 
                CUMPLIRLO QUE TE HE PROMETIDO, TE CUIDARÉ,
                POR DONDE QUIERA QUE VAYAS.`
            }</Typography>
            <Box sx={{position: "relative"}}>
                <TxtSubTitles sx={{
                    fontFamily: "'balgin'",
                    color: "black",
                    fontSize: "16px !important",
                    letterSpacing: "5px",
                    padding: "10px 20px",
                    lineHeight: 1.6
                }}>ATENTAMENTE</TxtSubTitles>
                <Typography sx={{
                    fontFamily: "'Buff'",
                    textAlign: "center",
                    lineHeight: 1,
                    color: "#a76c40",
                    textTransform: "none",
                    fontSize: "56px !important",
                    padding: "0% 12%",
                }}>{madre} & {padre}</Typography>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[50, 50]}>
            <Typography sx={{
                textAlign: "end",
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "10px !important",
                letterSpacing: "3px",
                marginRight: "30px",
                lineHeight: 1.3
            }}>{txtVersiculo ? txtVersiculo: "GÉNESIS 28:15"}</Typography>
        </ParallaxBannerLayer>
    </ParallaxBanner>)
}

export default Frases;
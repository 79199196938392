import '../styles/css/tmp-000-001.css'
import React, { useContext, useEffect, useState } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Grid, Typography, Box } from '@mui/material';
import { TextCarios } from '.';



const Banner = (props) => {
    const {
      nombre_corto = "Marcos & Maday",
      bkImage,
      deadline="31 Dic 2024 17:30:00 UTC",
      bgcolor,
      color
    } = props
    const [state, setState] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0})
    const leading0 = (num) => { return num < 10 ? "0" + num : num }
    
    const getTimeUntil = (date_time) => {
      const time = Date.parse(date_time) - Date.parse(new Date());
      if (time < 0) { setState({ days: 0, hours: 0, minutes: 0, seconds: 0 }) }
      else {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        setState({ days, hours, minutes, seconds });
      }
    };
  
    const [width, setWidth] = React.useState(0)
    const ref = React.useRef([])
  
    useEffect(() => {
      getTimeUntil(deadline);
      setInterval(() => {
        getTimeUntil(deadline)

      }, 1000);
    },[])
  
    useEffect(() => {
      if(!ref?.current?.clientWidth) {
        return;
      }
      setWidth(ref?.current?.clientWidth);
    }, [ref?.current?.clientWidth]);
  
    try{
      let tmp = "tmp-1"
      
      const Circle = ({refComponent, unity, children}) => (
        <Grid sx={{
            display: "grid",
            alignContent: "center !important",
            justifyItems: "center !important",
            margin: "0px !important"
        }} className='no-padding' item xs={3}>
          <Box sx={{
            height: "50px",
            width: "50px",
            borderRadius: "50px",
            display: "grid",
            alignContent: "center !important",
            justifyContent: "center !important"
          }}>
            <Typography sx={{
              color: "white",
              fontSize: "46px",
              fontFamily: "bright"
            }} ref={refComponent}>{unity}</Typography>
          </Box>
          <Box><Typography sx={{
            fontFamily: "bright !important",
            fontSize: "25px",
            color: "white"
          }}>
            {children}
          </Typography>
          </Box>
      </Grid>)
      
      return(<Box className='container'>
        <Box sx={{ position: "relative"
  }}>
          <ParallaxBanner 
            className={`${tmp}-parallax-banner`} 
            layers={[{ 
              image: bkImage,
              speed: 0,
              easing: [1, 1, 1, 1],
              scale: [1, 1, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true, 
            },{
                translateY: [2, 15],
                opacity: [3, 0.7],
                scale: [1, 1, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div style={{
                    position: "absolute",
                    top: "18px",
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                  }}>
                    <Typography sx={{
                      fontSize: "62px",
                      color: "white",
                      fontFamily: "hello"
                    }}>{nombre_corto}</Typography>
                  </div>
                ),
              },{
                translateY: [70, 80],
                opacity: [3.5, -1.5],
                scale: [1, 1, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div style={{
                    position: "absolute",
                    top: "18px",
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                  }}>
                    <Typography sx={{
                      fontSize: "55px",
                      color: "white",
                      fontFamily: "hello",
                      textShadow: "0px 0px 8px white",
                      fontWeight: "bold"
                    }}>Nos Casamos!</Typography>
                  </div>
                ),
            },{
              translateY: [-1, -1],
              scale: [1, 1.05, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              expanded: false,
              children: (
                <div style={{
                  position: "absolute",
                  bottom: "20px",
                  color: "white",
                  textAlign: "center",
                  width: "100%"
                }}>
                  <Grid container className={`${tmp}-container-counter-grid`}>
                    <Grid sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center"
                    }} className='no-padding' item xs={12}></Grid>
                    <Circle unity={leading0(state.days)}>días</Circle>
                    <Circle unity={leading0(state.hours)}>horas</Circle>
                    <Circle unity={leading0(state.minutes)}>min</Circle>
                    <Circle unity={leading0(state.seconds)}>seg</Circle>
                    {/* <Grid className='no-padding' item xl={4} lg={4} md={3} sm={2} xs={0}></Grid> */}
                  </Grid>
                </div>
              ),
            },
          ]}
          >
          </ParallaxBanner>
        </Box>
      </Box>)
    }catch(err){
      console.log(err)
    }
}

export default Banner
import React, { useEffect, useState } from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { Box } from '@mui/material';
import styled from 'styled-components';
import bkPadres from "../../images/seccion_padres-removebg-preview.png"
import { TextRobotoReg } from '.';

const Seccion = styled('div')({
    height: "100vh",
    background: "#f7f3ed"
})

const TextoRegular = styled('p')({
    letterSpacing: "4px",
    fontSize: "13px",
    width: "100%",
    textAlign: "center",
    padding: "10px",
    color: "#030100",
    fontFamily: "robotoregular"
})

const TextoThin = styled('p')({
    letterSpacing: "3px",
    fontSize: "9px",
    width: "100%",
    textAlign: "end",
    paddingRight: "20px",
    zIndex: 2,
    color: "#030100",
    fontFamily: "robotolight",
    fontWeight: "bold"
})

const TextoLigth = styled('p')({
    letterSpacing: "3px",
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
    color: "#030100",
    fontFamily: "robotolight",
    fontWeight: "bold"
})

const TextoSign = styled('p')({
    fontSize: "40px",
    width: "100%",
    textAlign: "center",
    color: "#313131",
    fontFamily: "klcupid",
    fontWeight: "bold"
})

const TextoPadres = styled('p')({
    fontFamily: "'amazingglight'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

const Padres = ({evento, sx}) => {
    const [padres, setPadres] = useState({})

    useEffect(()=>{
        if(evento?.padres){
            setPadres(evento.padres)
        }
    },[evento])

    try{
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
            <Box sx={{
                background: "black",
                "& div":{
                    inset: "-15px -90px !important"
                }
            }}>
                <ParallaxBannerLayer translateX={[-5,-5]} image={bkPadres} speed={-5} opacity={[0.3, 0.4]}/>
            </Box>
            <ParallaxBannerLayer opacity={[0, 1.5]} rotate={[-30, 30]} translateY={[18,18]} speed={-5}>
                <TextRobotoReg sx={{
                    padding: "10px 40px !important",
                    letterSpacing: "4px"
                }}>
                    "ASÍ QUE YA NO SON DOS, SINO UNA SOLA CARNE. 
                    POR LO TANTO, LO QUE DIOS HA UNIDO, QUE NO LO SEPARE 
                    NINGÚN HOMBRE"
                </TextRobotoReg>
                <TextoThin>MATEO 19:6</TextoThin>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[0, 1.5]} rotate={[25, -25]} translateY={[38,38]} speed={-5}>
                <TextoRegular sx={{
                    color: "black"
                }}>
                    CON LA COMPAÑÍA Y EL AMOR DE NUESTROS PADRES
                    </TextoRegular>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[-0.5, 2.5]} translateY={[43,43]} speed={-5}>
                <Box sx={{margin: "0px 10px"}}>
                    <TextoSign>____+____</TextoSign>
                </Box>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[2.1, -0.2]} translateX={[-50,50]} translateY={[50,50]} speed={-5}>
                <TextRobotoReg sx={{
                    fontSize: "24px",
                    letterSpacing: "4px",
                    marginBottom: "10px"
                }}>PADRES DEL NOVIO</TextRobotoReg>
                <TextRobotoReg sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px"
                }}>{padres?.padreNovio?padres.padreNovio.toUpperCase():""}</TextRobotoReg>
                <TextRobotoReg sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px"
                }}>{padres?.madreNovio ? padres.madreNovio.toUpperCase(): ""}</TextRobotoReg>
            </ParallaxBannerLayer>

            <ParallaxBannerLayer opacity={[2.1, -0.2]} translateX={[50,-50]} translateY={[60,60]} speed={-5}>
                <TextRobotoReg sx={{
                    fontSize: "24px",
                    letterSpacing: "4px",
                    marginBottom: "10px"
                }}>PADRES DE LA NOVIA</TextRobotoReg>
                <TextRobotoReg sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px"
                }}>{padres?.padreNovia ? padres.padreNovia.toUpperCase(): ""}</TextRobotoReg>
                <TextRobotoReg sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px"
                }}>{padres?.madreNovia ? padres.madreNovia.toUpperCase(): ""}</TextRobotoReg>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    )
    }catch(err){ console.log(err)}
  }

  export default Padres
import { Box } from "@mui/material"
import Banner from './Banner'
import { useContext, useEffect, useState } from "react"
import { Multimedia } from "../../context";
import { extract_file } from "../../functions";
import Invitado from "./Invitado";
import Recepcion from "./Recepcion";
import Obsequios from "./Obsequios";
import Confirmation from "./Confirmacion";
import Recuerdos from "./Recuerdos";
import Galeria from "./Galeria";
import PlayButton from "./Playbutton";
import Frases from "./Frases";
import { Footer } from "../../componentes";
import { formatInputDateStr } from "../../elementos/formatDate";

const Component = (data = []) => {
    const multimediaContext = useContext(Multimedia);
    const { obtener_filename, multimedia } = multimediaContext;

    const [secciones,   setSecciones]   = useState([])
    const [multimedia_, setMultimedia_] = useState([])
    const [evento, setEvento]           = useState({})
    const [padre, setPadre]             = useState("")
    const [madre, setMadre]             = useState("")
    const [invitado, setInvitado]       = useState({})
    const [fecha,  setFecha]            = useState("31 Mar 2024 17:30:00 UTC")
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        let evento = null
        if(data?.data?.evento)   evento = data.data.evento
        if(data?.data?.invitado) setInvitado(data.data.invitado)
        if(evento && evento?.apartados){
            setSecciones(evento.apartados)
            obtener_filename(evento._id)
            setEvento(evento)

            if(evento?.dtEvento){
                let fecha_epoch = evento?.dtEvento ? evento.dtEvento: null
                if(fecha_epoch){
                    var d = new Date(fecha_epoch); // The 0 there is the key, which sets the date to the epoch
                    setFecha(d)
                }
            }
        }
    },[data])

    useEffect(()=>{
        if(evento?.padres?.madre){setMadre(evento.padres.madre)}
        if(evento?.padres?.padre){setPadre(evento.padres.padre)}
    },[evento])

    useEffect(()=>{
        let result = {}
        if(multimedia.length > 0){
            multimedia.map((m_, m_i) => {
                if(m_?.s_filename){
                    let file_ = extract_file(m_, multimedia)

                    result = {
                        ...result,
                        ...file_
                    }
                }

            })
        }

        setMultimedia_({...result})

    },[multimedia])

    return(<Box id="main-all-container">
        <Box className="area-container-grid">
            {secciones["*button_play"] ? (<PlayButton/>): null}
            {secciones?.banner          ? (<Banner bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"} typetxt="Mi primer cumpleaños" nombre_corto={evento?.nombre ? evento.nombre: ""} bkImage={multimedia_?.banner ? multimedia_?.banner: null} data={data} deadline={fecha}/>): null}
            {secciones?.frases          ? (<Frases bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"} madre={madre} padre={padre}/>): null}
            {secciones?.invitado        ? (<Invitado bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"} evento={evento} invitado={invitado} bkImage={multimedia_?.invitados ? multimedia_.invitados: null}/>): null}
            {secciones?.recepcion       ? (<Recepcion
                bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}
                bkImage={multimedia_?.recepcion ? multimedia_.recepcion: null}
                hora_salon={evento?.hora_salon ? evento.hora_salon: null}
                maps_salon={evento?.maps_salon ? evento.maps_salon: null}
                fechaEvento={formatInputDateStr(evento.dtEvento)}
                direccion={evento?.dir_salon ? evento.dir_salon: null}
            />): null}
            {secciones?.obsequios       ? (<Obsequios bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"} />): null}
            {secciones?.confirmacion    ? (<Confirmation bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"} bkImage={multimedia_?.confirmacion ? multimedia_.confirmacion: null} guest={invitado} open={open} setOpen={setOpen}/>): null}
            {secciones?.galeria         ? (<Galeria bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"} bkImage={multimedia_?.galeria ? multimedia_.galeria: null}/>): null}
            {secciones?.recuerdos       ? (<Recuerdos bgcolor={evento?.color_1 ? evento.color_1: "#cfffff"}/>): null}
            <Footer/>
        </Box>
    </Box>)
}

export default Component
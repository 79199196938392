import '../styles/css/tmp-000-001.css'
import React, { useContext, useEffect, useState } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Grid, Typography, Box } from '@mui/material';
import { TextCarios } from './Textos';
import { TxtSubTitles } from './Textos';
import img_1 from "./assets/portada_1.png"
import img_2 from "./assets/portada_2.png"
import img_3 from "./assets/portada_3.png"



const Banner = ({bgcolor, nombre_corto = "", bkImage, data = false, deadline="31 Dic 2024 17:30:00 UTC"}) => {
    const [state, setState] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0})
    const leading0 = (num) => { return num < 10 ? "0" + num : num }
    
    const getTimeUntil = (date_time) => {
      const time = Date.parse(date_time) - Date.parse(new Date());
      if (time < 0) { setState({ days: 0, hours: 0, minutes: 0, seconds: 0 }) }
      else {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        setState({ days, hours, minutes, seconds });
      }
    };
  
    const [width, setWidth] = React.useState(0)
    const ref = React.useRef([])
  
    useEffect(() => {
      getTimeUntil(deadline);
      setInterval(() => {
        getTimeUntil(deadline)

      }, 1000);
    },[])
  
    useEffect(() => {
      if(!ref?.current?.clientWidth) {
        return;
      }
      setWidth(ref?.current?.clientWidth);
    }, [ref?.current?.clientWidth]);
  
    try{
      let tmp = "tmp-1"
      
      const Circle = ({refComponent, unity, children}) => (
        <Grid className="no-padding" sx={{
            display: "grid",
            alignContent: "center !important",
            justifyItems: "center !important"
        }} item xl={2} lg={2} md={2} sm={2} xs={2}>
          <Box sx={{
            // backdropFilter: "blur(150px)",
            // background: "#858e7ac2",
            height: "50px",
            width: "50px",
            minHeight: "50px !important",
            minwidth: "50px !important",
            borderRadius: "50px",
            display: "grid",
            alignContent: "center !important",
            justifyContent: "center !important"
          }}>
            <Typography className="hex_div" sx={{
              color: "white",
              fontSize: "25px",
              padding: "4px",
              fontFamily: "'balgin'"
            }} ref={refComponent}>
              {unity}</Typography>
          </Box>
          <Box sx={{
            fontSize: "18px !important",
            fontFamily: "'balgin' !important",
            letterSpacing: "1px",
            textTransform: "uppercase",
            color: "black",
          }} className={`${tmp}-container-label-counter`}>{children}</Box>
      </Grid>)
      
      return(<Box className='container'>
        <Box sx={{ position: "relative"
  }}>
          <ParallaxBanner 
            style={{
              height: "100vh",
              background: bgcolor,
              display: "grid",
              alignContent: "end"
            }}
            layers={[{ 
              image: bkImage,
              speed: 0,
              easing: [1, 1, 1, 1],
              scale: [1, 1.2, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true, 
            },{
                translateY: [5, 20],
                opacity: [3, 0.7],
                scale: [1, 1, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div style={{
                    position: "absolute",
                    top: "-40px",
                    color: "black",
                    textAlign: "center",
                    width: "100%",
                  }}>
                    <TxtSubTitles>-MI BABY SHOWER-</TxtSubTitles>
                  </div>
                ),
              },
              {
                translateY: [6, -26],
                opacity: [3, 0.7],
                scale: [1, 1, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div style={{
                    position: "absolute",
                    top: "80px",
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                  }}>
                    <div style={{
                      position: "absolute",
                      top: "60px",
                      right: "0px",
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                    }}>
                    <Box sx={{
                      // position: "relative",
                      height: "150px",
                      display: "grid",
                      justifyContent: "center"
                    }}>
                      <img src={img_3} style={{
                        width: "150px",
                        position: "absolute",
                        top: -50,
                        left: "30%"
                      }}/>
                      <TextCarios style={{
                        fontSize: "80px",
                        color: "#FF6D6D",
                        opacity: 0.8,
                        letterSpacing: "6px",
                        wordSpacing: "6px",
                        padding: "0px",
                        margin: "0px !important",
                      }}>{nombre_corto}</TextCarios>
                    </Box>
                    </div>
                  </div>
                ),
            },{
              translateY: [26, 56],
              opacity: [3, 0.7],
              scale: [1, 1, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              expanded: false,
              children: (
                <div>
                  <div style={{ textAlign: "center", width: "100%" }}>

                    <Box sx={{
                      marginTop: "90px"
                    }}>
                      <p style={{
                        fontFamily: "'balgin'",
                        color: "black",
                        textTransform: "uppercase",
                        textAlign: "end",
                        marginRight: "20px",
                        fontSize: "20px",
                        marginBottom: "30px",
                      }}>
                        ¿Cuánto Falta?
                      </p>
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item xs={4}/>
                      <Circle unity={leading0(state.days)}>días</Circle>
                      <Circle unity={leading0(state.hours)}>horas</Circle>
                      <Circle unity={leading0(state.minutes)}>min</Circle>
                      <Circle unity={leading0(state.seconds)}>seg</Circle>
                      {/* <Grid item xs={0}/> */}
                    </Grid>
                  </div>
                </div>
              ),
          }
          ]}
          >
          </ParallaxBanner>
          <Box sx={{position: "absolute", bottom: "0px", left: 0}}>
            <img src={img_1} style={{height:"60vh", minHeight: "380px", maxHeight: "395px"}}/>
          </Box>
          <Box sx={{position: "absolute", bottom: "0px", right: 0}}>
            <img src={img_2} style={{height:"150px"}}/>
          </Box>
        </Box>
      </Box>)
    }catch(err){
      console.log(err)
    }
}

export default Banner
import React, { useEffect, useState } from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { Box, Grid2, Typography } from '@mui/material';
import styled from 'styled-components';

const TextoSign = styled('p')({
    fontSize: "40px",
    width: "100%",
    textAlign: "center",
    color: "#313131",
    fontFamily: "klcupid",
    fontWeight: "bold"
})

const TextoPadres = styled('p')({
    fontFamily: "'amazingglight'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

const Padres = ({evento, sx, bgcolor}) => {
    const [padres, setPadres] = useState({})

    useEffect(()=>{
        if(evento?.padres){
            setPadres(evento.padres)
        }
    },[evento])

    try{
        return(<ParallaxBanner style={{ height: "45vh", aspectRatio: '2 / 1', background: bgcolor }}>
            <ParallaxBannerLayer opacity={[0, 2.5]} translateY={[23,23]} speed={-5}>
                <Typography sx={{
                    color: "black !important",
                    textAlign: "center",
                    margin: "0px 15px",
                    fontFamily: "bright",
                    fontSize: "22px"
                }}>Con la compañía y el amor de nuestros padres</Typography>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[-0.5, 2.5]} translateY={[40,40]} speed={-5}>
                <Box sx={{padding: "20px"}}>
                    <Grid2 container>
                        <Grid2 size={{xs:5}}>
                            <Typography sx={{
                                fontFamily: "brittany",
                                color: "black",
                                textAlign: "center",
                                fontSize: "24px",
                                marginBottom: "10px"
                            }}>Padres del novio</Typography>
                            <Typography sx={{
                                textAlign: "center",
                                fontFamily: "belleza_reg",
                                color: "black",
                                marginBottom: "5px"
                            }}>{padres?.padreNovio?padres.padreNovio:""}</Typography>
                            <Typography sx={{
                                fontFamily: "belleza_reg",
                                color: "black",
                                textAlign: "center",
                                marginBottom: "5px"
                            }}>{padres?.madreNovio ? padres.madreNovio: ""}</Typography>
                        </Grid2>
                        <Grid2 size={{xs:2}} sx={{
                            "& p":{
                                rotate: "90deg !important",
                                fontSize: "35px",
                                fontWeight: "normal"
                            }
                        }}>
                            <TextoSign style={{rotate: "90deg !important"}}>____+____</TextoSign>
                        </Grid2>
                        <Grid2 size={{xs:5}}>
                            <Typography sx={{
                                fontFamily: "brittany",
                                color: "black",
                                textAlign: "center",
                                fontSize: "24px",
                                marginBottom: "10px"
                            }}>Padres de la novia</Typography>
                            <Typography sx={{
                                fontFamily: "belleza_reg",
                                color: "black",
                                marginBottom: "5px",
                                textAlign: "center"
                            }}>{padres?.padreNovia ? padres.padreNovia: ""}</Typography>
                            <Typography sx={{
                                color: "black",
                                fontFamily: "belleza_reg",
                                marginBottom: "5px",
                                textAlign: "center"
                            }}>{padres?.madreNovia ? padres.madreNovia: ""}</Typography>
                        </Grid2>
                    </Grid2>
                </Box>
                <Box
                sx={{margin: "0px 10px"}}>
                </Box>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    )
    }catch(err){ console.log(err)}
  }

  export default Padres
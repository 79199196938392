import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, styled, Typography } from "@mui/material"
import transfer from '../../images/transfer.png'
import sobre from '../../images/sobre_2.png'
import liverpool from '../../images/liverpool_pink.png'
import { ExpandMore } from "@mui/icons-material"

const Obsequios = ({n_cuenta, bgcolor, banco}) => {    
  return (
    <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer translateY={[36, 28]} opacity={[-0.5, 2.5]}>
            <Typography sx={{
                fontFamily: "bright",
                color: "black",
                textAlign: "center",
                marginTop: "10px",
                padding: "0px 20px",
                textTransform: "uppercase",
                fontSize: "20px"
            }}>Lo más importante es tu presencia, pero si deseas hacernos un regalos, puede ser</Typography>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[8, 0]} opacity={[-0.5, 2.5]}>
            <TextSouthwalles sx={{
                fontSize: "65px",
                color: "black",
                fontFamily: "brittany"
            }}>Obsequios</TextSouthwalles>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[48, 30]}>
            <Box sx={{width: "100%"}}>
                <Grid container sx={{padding: "0px 40px"}}>
                    <Grid item xs={4}>
                        <img src={sobre} style={{
                            width: "100%"
                        }}/>
                    </Grid>
                    <Grid item xs={8} sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center"
                    }}>
                        <Typography sx={{color: "black", fontFamily: "bright", fontSize: "18px", textTransform: "uppercase"}}>Lluvia de sobres</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{padding: "0px 40px", marginTop: "60px"}}>
                    <Grid item xs={4}>
                        <img src={transfer} style={{
                            width: "100%"
                        }}/>
                    </Grid>
                    <Grid item xs={8} sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        paddingLeft: "20px"
                    }}>
                        <Accordion sx={{
                            background: "transparent",
                            boxShadow: "none"
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{fontFamily: "bright", fontSize: "18px", textTransform: "uppercase"}}
                            >
                                Transferencia
                            </AccordionSummary>
                            <AccordionDetails>
                                <p style={{textAlign: "center", fontFamily: "bright"}}>{banco ? banco: "BBVA"}</p>
                                <p style={{fontFamily: "bright", fontSize: "12px"}}>{n_cuenta ? n_cuenta: "0000 0000 0000 0000"}</p>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container sx={{padding: "0px 30px", marginTop: "60px"}}>
                    <Grid item xs={4}>
                        <img src={liverpool} style={{
                            width: "100%"
                        }}/>
                    </Grid>
                    <Grid item xs={8} sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center"
                    }}>
                        <Accordion sx={{
                            background: "transparent",
                            boxShadow: "none"
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{fontFamily: "bright", fontSize: "18px", textTransform: "uppercase"}}
                            >
                                Mesa de regalos
                            </AccordionSummary>
                            <AccordionDetails>
                                <p style={{textAlign: "center", fontFamily: "bright"}}>No. de evento</p>
                                <p style={{fontFamily: "bright", fontSize: "12px"}}>a1239uf349304049559kf</p>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
        {/* <ParallaxBannerLayer translateY={[58, 25]}>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Lluvia de sobres</Typography>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>ó</Typography>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Transferencia</Typography>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[74, 25]}>
            <Box sx={{display: "grid", width: "100%", justifyContent: "center"}}>
                <img src={bbva_img} style={{
                    width: "inherit",
                    height: "60px",
                    width: "160px"
                }}/>
            </Box>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Madai Cortés Cruz</Typography>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>{n_cuenta ? n_cuenta: ""}</Typography>
        </ParallaxBannerLayer> */}
    </ParallaxBanner>
    );
}

export default Obsequios

export const TextSouthwalles = styled('p')({
    fontFamily: "'southwalles'",
    letterSpacing: "6px",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})
import { Alert, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import url from '../images/banner-login.jpg'
import {useParams} from "react-router-dom";
import { Auth } from "../context";

const Acceso = () => {
    const authContext = useContext(Auth);
    const { login, mensaje, usuario, setToken, token, usuarioAutenticado} = authContext;

    const {code, code_manual}  = useParams();
    const [codigo, setCodigo]   = useState("");
    const [timeError, setTimeError] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        let save_token = localStorage.getItem("token");
        if(save_token){
            setToken(save_token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{},[code])

    useEffect(()=>{
        if(token){
            usuarioAutenticado(token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token])

    useEffect(()=>{
        if(mensaje){
            setTimeError(true)
            setTimeout(()=>{setTimeError(false)}, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mensaje])

    useEffect(()=>{
        if(usuario && usuario !== "error"){
            navigate("/invitaciones")
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[usuario])

    useEffect(()=>{
        if(code){
            login(code)
        }
    },[code])

    return(<Box>
        <Box sx={{
            height: '92vh',
            backgroundImage: `url("${url}")`,
            backgroundSize: "cover",
            display: 'grid',
            alignContent: 'center',
        }}>
            <Box sx={{
                width: '100vw',
                padding: '2vw 0px',
                borderRadius: "5px",
                justifyContent: "center",
                textAlign: "center",
                display: 'grid'
            }}>
                <Box sx={{
                    width: "70vw",
                    maxWidth: "300px",
                    background: 'white',
                    borderRadius:"5px"
                }}>
                    <Typography sx={{fontWeight: "bold",fontSize: "23px",fontFamily:"'Antic Didone'", margin: '10px 0px'}}>Invitación Cordial</Typography>
                    {code_manual ? (<Typography
                        sx={{marginY: '30px', fontSize: "10px", color: "black"}}>
                            Da click en el botón de acceder para ver la invitación.
                        </Typography>
                    ): null}
                    <Box hidden={code_manual ? true: false} margin="0px 40px">
                        <TextField type={code_manual ? "hidden": "text"} onChange={e => setCodigo(e.target.value)} value={codigo}/>
                    </Box>
                    <Box>
                        <Button sx={{
                            width:"100%", 
                            color: "white !important", 
                            marginTop: !code_manual ? "20px": "0px",
                            background: "black",
                            borderRadius: "0px 0px 5px 5px",
                            "&:hover":{
                                background: "#a6967d !important"
                            }
                        }} onClick={() => { if(code_manual){ login(code_manual) }}}>Acceder</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box sx={{
            background: 'black !important', 
            height: '8vh',
            display: "grid",
            alignContent: "center",
            fontSize: "10px",
            justifyContent: "center"
        }}>
            <Typography sx={{color: 'white', fontSize: '12px'}}>Desarrollado por Convite © 2023</Typography>
        </Box>

        {timeError ? (<Stack sx={{ width: '300px', position: "absolute", bottom: 20, left: 20 }} spacing={2}>
            <Alert variant="filled" severity="error">{mensaje?.msg ? mensaje.msg: "error"}</Alert>
        </Stack>): null}
    </Box>)
}

export default Acceso
import { Box, Grid, styled } from "@mui/material"
import { TextCarios, TextRobotoReg } from "."
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import ubicacion from '../../images/icono_ubicacion.png'
import calendario from '../../images/icono_calendario.png'
import reloj from '../../images/icono_reloj.png'

const VerMapaBtn = styled('div')({
    color: "black",
    background: "#bb8f62",
    width: "fit-content",
    padding: "20px",
    borderRadius: "20px"
})

const center_container = {display: "grid", alignContent: "center", justifyContent: "center", margin: "20px 0px"}

const Religiosa = ({bkImage, maps_iglesia, hora_iglesia}) => {
    let dir = "Av. Morenita Mia 2200, Roble San Nicolás, 66420 San Nicolás de los Garza, N.L."
    let name_dir = 'Parroquia de Nuestra Señora de San Juan de los Lagos'
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer opacity={[0.1, 0.4]} image={bkImage} speed={-20} />
        <ParallaxBannerLayer translateY={[22, 24]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "30px",
                    color: "#413524",
                    lineHeight: "0px",
                }}>Ceremonia Religiosa</TextCarios>
                <TextRobotoReg sx={{
                    marginTop: "50px",
                    letterSpacing: "3px",
                    padding: "0px 20px",
                    fontSize: "20px"
                }}>{name_dir.toUpperCase()}</TextRobotoReg>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[35, 40]} translateX={[50, -55]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <Grid container sx={{padding: "50px"}}>
                    <Grid item xs={4} sx={center_container}>
                        <img width="80px" src={`${ubicacion}`}></img>
                    </Grid>
                    <Grid item xs={8} sx={center_container}>
                        <TextRobotoReg sx={{
                            letterSpacing: "2px",
                            fontSize: "15px",
                            textTransform: "uppercase"
                        }}>{dir}</TextRobotoReg>
                    </Grid>
                    <Grid item xs={4} sx={center_container}>
                        <img width="80px" src={`${calendario}`}></img>
                    </Grid>
                    <Grid item xs={8} sx={center_container}>
                        <TextRobotoReg sx={{
                            letterSpacing: "2px",
                            fontSize: "15px",
                            textTransform: "uppercase"
                        }}>Jueves, 25 abril 2024</TextRobotoReg>
                    </Grid>
                    <Grid item xs={4} sx={center_container}>
                        <img style={{paddingRight: "2px"}} width="65px" src={`${reloj}`}></img>
                    </Grid>
                    <Grid item xs={8} sx={center_container}>
                        <TextRobotoReg sx={{
                            letterSpacing: "2px",
                            fontSize: "15px",
                            textTransform: "uppercase"
                        }}>Hora: 6:00 p.m.</TextRobotoReg>
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[61, 73]} opacity={[2.3, 0.1]}>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                <VerMapaBtn onClick={e=>{
                    let map_dir = maps_iglesia ? maps_iglesia: "https://maps.app.goo.gl/pnExeQ3xwja6aRv3A"
                    var win = window.open(map_dir, '_blank');
                    // Cambiar el foco al nuevo tab (punto opcional)
                    win.focus();
                }}>
                    <TextRobotoReg sx={{fontSize: "20px", fontWeight: "bold"}}>VER MAPA</TextRobotoReg>
                </VerMapaBtn>
            </Box>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

const Recepcion = ({bkImage, maps_salon, hora_salon}) => {
    let dir = "Atrás de la PGR, C. P.º de los Lirios 170, Nueva Castilla, 66052 Cdad. Gral. Escobedo, N.L."
    let name_dir = 'Gran Jardin Secreto Eventos'
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer opacity={[0.1, 0.4]} image={bkImage} speed={-20} />
        <ParallaxBannerLayer translateY={[22, 24]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "40px",
                    color: "#413524",
                    lineHeight: "0px",
                }}>Recepción</TextCarios>
                <TextRobotoReg sx={{
                    marginTop: "50px",
                    letterSpacing: "4px",
                    fontSize: "20px"
                }}>{name_dir.toUpperCase()}</TextRobotoReg>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[33, 38]} translateX={[50, -55]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <Grid container sx={{padding: "50px"}}>
                    <Grid item xs={4} sx={center_container}>
                        <img width="80px" src={`${ubicacion}`}></img>
                    </Grid>
                    <Grid item xs={8} sx={center_container}>
                        <TextRobotoReg sx={{
                            letterSpacing: "2px",
                            fontSize: "15px",
                            textTransform: "uppercase",
                            color: "#413524",
                        }}>{dir}</TextRobotoReg>
                    </Grid>
                    <Grid item xs={4} sx={center_container}>
                        <img width="80px" src={`${calendario}`}></img>
                    </Grid>
                    <Grid item xs={8} sx={center_container}>
                        <TextRobotoReg sx={{
                            letterSpacing: "4px",
                            fontSize: "15px",
                            color: "#413524",
                            textTransform: "uppercase"
                        }}>Jueves, 25 abril 2024</TextRobotoReg>
                    </Grid>
                    <Grid item xs={4} sx={center_container}>
                        <img style={{paddingRight: "2px"}} width="65px" src={`${reloj}`}></img>
                    </Grid>
                    <Grid item xs={8} sx={center_container}>
                        <TextRobotoReg sx={{
                            letterSpacing: "4px",
                            color: "#413524",
                            fontSize: "18px",
                            textTransform: "uppercase"
                        }}>8:00 P.M.</TextRobotoReg>
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[61, 73]} opacity={[2.3, 0.1]}>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                <VerMapaBtn onClick={e=>{
                    let map_dir = maps_salon ? maps_salon: "https://maps.app.goo.gl/hyKPA4tS1jmqu5AZ6"
                    var win = window.open(map_dir, '_blank');
                    // Cambiar el foco al nuevo tab (punto opcional)
                    win.focus();
                }}>
                    <TextRobotoReg sx={{fontSize: "20px", fontWeight: "bold"}}>VER MAPA</TextRobotoReg>
                </VerMapaBtn>
            </Box>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

const Ubicaciones = ({imagenes, maps_salon, maps_iglesia, hora_iglesia, hora_salon}) => {
    console.log(imagenes)
    return(<Box>
        <Religiosa maps_iglesia={maps_iglesia} hora_iglesia={hora_iglesia} bkImage={imagenes?.iglesia ? imagenes.iglesia: false}/>
        <Recepcion maps_salon={maps_salon} hora_salon={hora_salon} bkImage={imagenes?.recepcion ? imagenes.recepcion: false}/>
    </Box>)
}

export default Ubicaciones
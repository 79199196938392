import { useContext, useState, useEffect } from "react";
import { Box, Button, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { TextCarios, TxtSubTitles } from "./Textos";
import { Auth } from "../../context";

const Confirmacion = ({
    color_2,
    bkImage, bgImage, guest, evento, open, setOpen, bgcolor = "#FFF2EE", 
    txt_1, txt_2, btn_1, btn_2
}) => {
    const { confirmarAsistencia, confirmado } = useContext(Auth);
    const [confirm, setConfirm] = useState(confirmado ? 1 : 0);
    const [asistidos, setAsistidos] = useState(guest?.reservaciones || 0);
    function generarLinkWhatsApp(mensaje, telefono) {
        // Codificar el mensaje para URL
        const mensajeCodificado = encodeURIComponent(mensaje);
    
        // Retornar el enlace de WhatsApp con el mensaje y el número
        return `https://wa.me/${telefono}?text=${mensajeCodificado}`;
    }

    const confirmacion_wha = (noAsistire) => {
        if(noAsistire === 1010){
            const telefono = evento?.telefono ? "52"+evento.telefono: "none"; // Asegúrate de incluir el código de país

            if(telefono !== "none"){ 
                const link = generarLinkWhatsApp(evento?.txtWhatsapp ? evento?.txtWhatsapp: "Hola, ¿Como estas?", telefono)
                window.open(link, '_blank');
            }
        }else{
            const telefono2 = evento?.telefono2 ? "52"+evento.telefono2: "none"; // Asegúrate de incluir el código de país

            if(telefono2 !== "none"){ 
                const link = generarLinkWhatsApp(evento?.txtWhatsapp ? evento?.txtWhatsapp: "Hola, ¿Como estas?", telefono2)
                window.open(link, '_blank');
            }
        }
    }
    const handleOpen = (noAsistire = 0) => {
        if(evento?.whatsapp){ confirmacion_wha(noAsistire) }else{setOpen(true)}
    }
    const handleClose = () => setOpen(false);

    const aceptarAsistencia = (noAsistire = false) => {
        if(evento?.whatsapp){ confirmacion_wha(noAsistire)}
        else{
            confirmarAsistencia(noAsistire ? 1010 : asistidos);
            setConfirm(noAsistire)
            handleClose();
        }
    };

    const handleIncrement = () => {
        if (asistidos < guest?.reservaciones) setAsistidos(asistidos + 1);
    };

    const handleDecrement = () => {
        if (asistidos > 0) setAsistidos(asistidos - 1);
    };

    useEffect(() => {
        if (guest) {
            setConfirm(guest?.confirm ? 1 : 0);
            setAsistidos(guest?.reservaciones || 0);
        }
    }, [guest]);

    const TxtTitle = styled('p')({
        color: "black", fontFamily: "Belleza", fontWeight: "bold", fontSize: "1.125rem" // 18px
    });

    const TxtSub = styled('p')({
        color: "black", fontSize: "0.75rem", fontFamily: "Belleza" // 12px
    });

    return (
        <Box>
            <Modal open={open} onClose={handleClose} sx={{ display: "grid", justifyContent: "center" }}>
                <Box sx={{ position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "80%", bgcolor: 'background.paper', boxShadow: 24, borderRadius: "0.5rem", p: 2, height: "fit-content" }}>
                    <Close onClick={handleClose} sx={{ cursor: "pointer", position: "absolute", top: "0.625rem", right: "0.625rem" }} />
                    <TxtTitle>¿Deseas confirmar tu asistencia?</TxtTitle>
                    <TxtSub sx={{ marginTop: "0.625rem" }}>
                        Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
                    </TxtSub>
                    <Grid container sx={{ marginTop: "1.25rem" }}>
                        <Grid item xs={5}>
                            <Grid container>
                                <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                    <Button onClick={handleIncrement}><Add sx={{ color: "white", background: "black" }} /></Button>
                                </Grid>
                                <Grid item xs={8} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                    <TextField variant='standard' type='hidden' value={asistidos} />
                                    <TxtSub sx={{ fontWeight: "bold" }}>{asistidos} persona{asistidos > 1 ? "s" : ""}</TxtSub>
                                </Grid>
                                <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                    <Button onClick={handleDecrement}><Remove sx={{ color: "white", background: "black" }} /></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                            <TxtSub onClick={() => aceptarAsistencia()} sx={{ color: "white", background: "black", textAlign: "center", padding: "0.4375rem 0.3125rem", borderRadius: "0.3125rem" }}>Confirmar</TxtSub>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor, position: "relative"}}>
                <ParallaxBannerLayer translateY={[12, 0]} opacity={[-1, 3]} style={{ zIndex: 3 }}>
                    <Typography sx={{ color: color_2, padding: "0px", marginTop: "1.25rem !important", fontSize: "3.125rem", fontFamily: "Buff", textAlign: "center" }}>Confirmar Asistencia</Typography>
                    <Box sx={{ marginTop: "1.25rem" }}>
                        <Typography sx={{ fontFamily: "balgin", color: "black", padding: "0px", letterSpacing: "0.1875rem", textAlign: "center", fontSize: "1.125rem" }}>
                            {txt_1 || "ESPERAMOS QUE NOS PUEDAN ACOMPAÑAR"}
                        </Typography>
                    </Box>
                    {confirm > 0 ? (
                        <TextCarios sx={{ color: color_2, marginTop: "2.5rem", fontSize: "2.5rem" }}>Gracias</TextCarios>
                    ) : (
                        <Grid container sx={{ marginTop: "2.5rem" }}>
                            <Grid item xs={6} sx={{ padding: "0.3125rem" }}>
                                <Box onClick={handleOpen} sx={{ cursor: "pointer", border: `2px solid ${color_2}`, background: "transparent", borderRadius: "100px", padding: "0.75rem", minHeight: "4.375rem", display: "grid", alignContent: "center" }}>
                                    <TxtSubTitles sx={{ fontFamily: "'balgin'", color: "black", padding: "0px", letterSpacing: "0.125rem", fontSize: "0.9375rem" }}>{btn_1 || "SI, POR NADA ME LO PIERDO"}</TxtSubTitles>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ padding: "0.3125rem" }}>
                                <Box onClick={() => aceptarAsistencia(1010)} sx={{ cursor: "pointer", border: `2px solid ${color_2}`, background: "transparent", borderRadius: "100px", padding: "0.75rem", minHeight: "4.375rem", display: "grid", alignContent: "center" }}>
                                    <TxtSubTitles sx={{ fontFamily: "'balgin'", color: "black", padding: "0px", letterSpacing: "0.125rem", fontSize: "0.9375rem" }}>{btn_2 || "NO PODRE ASISTIR, LO LAMENTO"}</TxtSubTitles>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    <Box sx={{ padding: "1.25rem 1.875rem", marginTop: "1.25rem" }}>
                        <TxtSubTitles sx={{ fontFamily: "'balgin'", color: "black", padding: "0px", letterSpacing: "0.25rem", fontSize: "1.125rem" }}>
                            {txt_2 || "DA CLIC EN EL BOTÓN Y AYUDANOS CONFIRMANDO TU ASISTENCIA LO ANTES POSIBLE"}
                        </TxtSubTitles>
                    </Box>
                </ParallaxBannerLayer>
                <ParallaxBannerLayer translateY={[60, 60]} opacity={[3, -1]} style={{ zIndex: 3 }}>
                    <img src={bgImage} width="100%" />
                </ParallaxBannerLayer>

            </ParallaxBanner>
        </Box>
    );
};

export default Confirmacion;

import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextBuff, TextCarios, TextGlas, TxtSubTitles } from "./Textos"
import { Box } from "@mui/material"
import { Facebook, Instagram } from "@mui/icons-material";
import img_1 from "./assets/textoYrecuerdos_1.png"
import img_2 from "./assets/recuerdos_2.png"


const Obsequios = ({bgcolor}) => {
    return (<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer opacity={[-1, 2.1]} translateY={[0, 0]}>
            <Box sx={{position: "absolute", top: 0, left: 0, width: "100%"}}>
                <img src={img_1} width="100%"/>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.1]} translateY={[0, 0]}>
            <Box sx={{position: "absolute", bottom: 0, left: 0, width: "100%"}}>
                <img src={img_2} style={{maxHeight: "350px"}}/>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[16, 16]} opacity={[-1, 2.5]}>
            <TextCarios sx={{
                color: "#FF6D6D",
                padding: "0px",
                marginTop: "20px !important",
                fontSize: "60px"
            }}>Recuerdos</TextCarios>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[24, 24]} opacity={[-1, 2.5]}>
            <Box sx={{padding: "50px"}}>
                <TxtSubTitles sx={{
                    fontFamily: "'balgin'",
                    color: "black",
                    padding: "0px",
                    letterSpacing: "4px",
                    fontSize: "18px",
                    lineHeight: "30px"
                }}>
                    COMPARTENOS Y Etiquetanos en las publicaciones EN REDES SOCIALES de mi Babyshower utilizaNDO EL hashtag
                </TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[68, -10]} opacity={[-1, 2.5]}>
            <TextBuff sx={{color: "#FF6D6D", padding: "0px", marginTop: "30px !important", fontSize: "55px"}}>
                #Baby_Kath
            </TextBuff>
            <Box sx={{display: "grid", justifyContent: "center", marginTop: "20px", "& svg": {fontSize: "40px"}}}>
                <Box sx={{display: "flex"}}>
                    <Facebook sx={{color: "#A76C40"}}/>
                    <Instagram sx={{color: "#A76C40"}}/>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[50, 60]} opacity={[-1, 2.5]}>
            <Box sx={{marginTop: "130px"}}>
                <TextGlas sx={{
                    color: "white",
                    textShadow: "0px 0px 12px #FF6D6D",
                    fontSize: "50px"

                }}>Celebremos</TextGlas>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[58, 80]} opacity={[-1, 2.5]}>
            <Box>
                <TxtSubTitles sx={{
                    fontFamily: "'balgin'",
                    color: "#FF6D6D",
                    padding: "0px",
                    letterSpacing: "4px",
                    fontSize: "28px",
                }}>Juntos</TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>);
}

export default Obsequios
import { Meses } from "./Catalogo"

const number_date = epochTime => {
    let date = new Date(epochTime)
    const day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day_sem = date.getDay();
    let year = date.getFullYear()

    return [day, month, year, day_sem]
}

export const formatDate = epochTime => {
    let fecha_num = number_date(epochTime)
    return `${fecha_num[0]} | ${Meses(fecha_num[1])} | ${fecha_num[2]}`;
}

export const formatInputDate = epochTime => {
    let fecha_num = number_date(epochTime)
    return `${fecha_num[0]} | ${fecha_num[1]} | ${fecha_num[2]}`;
}

export const formatInputDateStr = epochTime => {
    let fecha_num = number_date(epochTime)
    return `${Dias_fecha(fecha_num[3])} ${fecha_num[0]} de ${Mes_fecha(fecha_num[1])} ${fecha_num[2]}`;
}

export const DecimalYear = (fecha_1, fecha_2) => {
    return parseFloat((fecha_1-fecha_2)/(1000*60*60*24*365.25)).toFixed(2);
}

export const DecimalToMonths = (unidades) => {
    let enteros = Math.trunc(unidades), decimales = unidades - enteros;
    let decimales_mounths = decimales * 12, meses = Math.trunc(decimales_mounths);

    return `${enteros > 0 ? enteros > 1 ? `${enteros} años`: `${enteros} año`: ""} ${meses > 0 ? meses > 1 ? `${meses} meses`: `${meses} mes`: ""}`
}

const Dias_fecha = no => {
    switch(no){
        case 0: return "Domingo";
        case 1: return  "Lunes";
        case 2: return  "Martes";
        case 3: return  "Miercoles";
        case 4: return  "Jueves";
        case 5: return  "Viernes";
        default: return "Sábado";
    }
}

const Mes_fecha = no => {
    switch(parseInt(no)){
        case 1: return  "Enero";
        case 2: return  "Febrero";
        case 3: return  "Marzo";
        case 4: return  "Abril";
        case 5: return  "Mayo";
        case 6: return  "Junio";
        case 7: return  "Julio";
        case 8: return  "Agosto";
        case 9: return  "Septiembre";
        case 10: return  "Octubre";
        case 11: return  "Noviembre";
        default: return "Diciembre";
    }
}
import { Box } from "@mui/material"
import {
    BloqueAnimacion,
    Seccion,
    TextAdega,
    TextAmazing,
    TextPlanner,
    TextRoboto,
    TextRobotoReg,
    TextSouthwalles
} from "."
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useEffect, useState } from "react"
import img from '../../images/icono_invitado.png'

const Invitado = ({invitado}) => {
    const [nombre, setNombre] = useState("")
    const [reservaciones, setReservacion] = useState(0)
    useEffect(()=>{
        if(invitado?.nombre){
            setNombre(invitado.nombre)
        }
        setReservacion(invitado.reservaciones)
    },[invitado])
    let phrase = "Con mucha ilusión queremos que formes parte de nuestra unión matrimonial"
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[20, 30]} translateX={[30, -30]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
                <TextRobotoReg sx={{
                    color: "black",
                    wordSpacing: "1px",
                    lineHeight: "25px !important",
                    fontSize: "18px",
                    padding: "0px 40px"
                }}>{phrase.toUpperCase()}</TextRobotoReg>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[42, 28]} translateX={[-30, 30]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
                <div style={{
                    width: "400px",
                    backgroundImage: `url("${img}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "25vh",
                    display: "grid",
                    alignContent: "center",
                    justifyContent: "center"
                }}>
                    <TextRobotoReg sx={{
                        fontSize: "20px !important",
                        fontWeight: "bold",
                        height: "24px",
                        color: "#4b4432"
                    }}>{nombre.toUpperCase()}</TextRobotoReg>
                    <TextRobotoReg sx={{
                        fontSize: "20px !important",
                        color: "#4b4432"
                    }}>{`(${reservaciones})`}</TextRobotoReg>
                </div>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            bottom: "80px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRobotoReg sx={{
                    fontSize: "25px !important",
                    height: "50px",
                    color: "#4b4432",
                    fontWeight: "bold"
                }}>NOS VEMOS EL DÍA</TextRobotoReg>
            </Box>
            <Box>
                <TextRobotoReg sx={{
                    fontSize: "32px !important",
                    color: "#4b4432",
                    fontWeight: "100"
                }}>25 | 04 | 2024</TextRobotoReg>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Invitado
import '../styles/css/tmp-000-001.css'
import React, { useContext, useEffect, useState } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Grid, Typography, Box } from '@mui/material';
import { TextCarios } from '.';


// const CountdownTimer = ({initialTime=0, alert="", setAlert = (e:any) => console.log(e)}) => {
// 	// Initial time in seconds (1 hour)
// 	useEffect(()=>{
// 		setTimeRemaining(initialTime * 60)
// 	},[initialTime])

// 	useEffect(()=>{
		
// 	}, [alert])

// 	const [timeRemaining, setTimeRemaining] = useState(initialTime);
  
// 	useEffect(() => {
// 	  const timerInterval = setInterval(() => {
// 		setTimeRemaining((prevTime) => {
// 		  if (prevTime === 0) {
// 			clearInterval(timerInterval);
// 			// Perform actions when the timer reaches zero
// 			console.log('Countdown complete!');
// 			return 0;
// 		  } else {
// 			return prevTime - 1;
// 		  }
// 		});
// 	  }, 1000);
  
// 	  // Cleanup the interval when the component unmounts
// 	  return () => clearInterval(timerInterval);
// 	}, []); // The empty dependency array ensures the effect runs only once on mount
  
// 	// Convert seconds to hours, minutes, and seconds
// 	// const hours = Math.floor(timeRemaining / 3600);
// 	const minutes = Math.floor((timeRemaining % 3600) / 60);
// 	const seconds = timeRemaining % 60;
  
// 	return (
// 	  <div>
// 		{minutes === 0 && seconds === 0? (<p style={{color: "#676c73 !important"}}>Volver a enviar</p>):(<p
// 			style={{fontSize: "11px", color: "#676c73 !important"}}>
// 			Tiempo restante {`${minutes < 10 ? "0" + minutes: minutes}:${seconds < 10 ? "0" + seconds: seconds}`}
// 			{alert && alert !="" ? (<p>{alert}</p>): null}
// 		</p>)}
// 	  </div>
// 	);
// };

const Banner = ({nombre_corto = "", bkImage, data = false, deadline="31 Dic 2024 17:30:00 UTC"}) => {
    const [state, setState] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0})
    const leading0 = (num) => { return num < 10 ? "0" + num : num }
    
    const getTimeUntil = (date_time) => {
      const time = Date.parse(date_time) - Date.parse(new Date());
      if (time < 0) { setState({ days: 0, hours: 0, minutes: 0, seconds: 0 }) }
      else {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        setState({ days, hours, minutes, seconds });
      }
    };
  
    const [width, setWidth] = React.useState(0)
    const ref = React.useRef([])
  
    useEffect(() => {
      getTimeUntil(deadline);
      setInterval(() => {
        getTimeUntil(deadline)

      }, 1000);
    },[])
  
    useEffect(() => {
      if(!ref?.current?.clientWidth) {
        return;
      }
      setWidth(ref?.current?.clientWidth);
    }, [ref?.current?.clientWidth]);
  
    try{
      let tmp = "tmp-1"
      
      const Circle = ({refComponent, unity, children}) => (
        <Grid sx={{
            display: "grid",
            alignContent: "center !important",
            justifyItems: "center !important"
        }} className='no-padding' item xl={1} lg={1} md={1.5} sm={2} xs={3}>
          <Box sx={{
            // backdropFilter: "blur(150px)",
            background: "#858e7ac2",
            height: "50px",
            width: "50px",
            borderRadius: "50px",
            display: "grid",
            alignContent: "center !important",
            justifyContent: "center !important"
          }}>
            <Typography sx={{
              color: "black",
              fontWeight: "bold",
              fontSize: "16px",
            //   fontFamily: "'Sacramento'",
              fontFamily: "'garetbook'"
            }} ref={refComponent}>{unity}</Typography>
          </Box>
          <Box sx={{
            fontSize: "22px !important",
            letterSpacing: "-1px",
            textShadow: "0px 2px 4px black"
          }} className={`${tmp}-container-label-counter`}>{children}</Box>
      </Grid>)
      
      return(<Box className='container'>
        <Box sx={{ position: "relative"
  }}>
          <ParallaxBanner 
            className={`${tmp}-parallax-banner`} 
            layers={[{ 
              image: bkImage,
              speed: 0,
              easing: [1, 1, 1, 1],
              scale: [1, 1.2, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true, 
            },{
                translateY: [5, 20],
                opacity: [3, 0.7],
                scale: [1, 1, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div style={{
                    position: "absolute",
                    top: "18px",
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                  }}>
                    <TextCarios style={{
                      fontSize: "25px",
                      color: "black",
                    }}>{nombre_corto}</TextCarios>
                  </div>
                ),
              },
            
            {
              translateY: [-5, 30],
              scale: [1, 1.05, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              expanded: false,
              children: (
                <div style={{
                  position: "absolute",
                  bottom: "20px",
                  color: "white",
                  textAlign: "center",
                  width: "100%",
                //   backdropFilter: "blur(4px)"
                }}>
                  <Grid container spacing={2} className={`${tmp}-container-counter-grid`}>
                    <Grid sx={{display: "grid",
                        alignContent: "center",
                        justifyContent: "center"
                    }} className='no-padding' item xl={4} lg={4} md={3} sm={2} xs={0} ></Grid>
                    <Circle unity={leading0(state.days)}>días</Circle>
                    <Circle unity={leading0(state.hours)}>horas</Circle>
                    <Circle unity={leading0(state.minutes)}>minutos</Circle>
                    <Circle unity={leading0(state.seconds)}>segundos</Circle>
                    <Grid className='no-padding' item xl={4} lg={4} md={3} sm={2} xs={0}></Grid>
                  </Grid>
                </div>
              ),
            },
          ]}
          >
          </ParallaxBanner>
        </Box>
      </Box>)
    }catch(err){
      console.log(err)
    }
}

export default Banner
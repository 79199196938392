import { useEffect } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useSnapCarousel } from "react-snap-carousel"
import { TextRobotoReg, VerMapa } from "."
import { Box, Grid, styled } from "@mui/material"
import bkPadres from "../../images/seccion_padres-removebg-preview.png"
import ico_tel from "../../images/tel.png"
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, LocationCity, Star } from "@mui/icons-material"

const Hospedaje = ({bkImage}) => {
    useEffect(()=>{console.log(bkImage)},[bkImage])
    let hotel_op1 = "https://www.google.com/maps/place/Best+Western+Bazarell+Inn/@25.189476,-99.8556518,17z/data=!3m1!4b1!4m9!3m8!1s0x867cd71d3220ade7:0x16d3b4fea6628a0a!5m2!4m1!1i2!8m2!3d25.189476!4d-99.8556518!16s%2Fg%2F11xfk3b53?entry=ttu"
    let hotel_op2 = "https://www.google.com/maps/place/Capital+O+Monte+Salerno+Hotel+%26+Suites/@25.1889533,-99.8225631,17z/data=!3m1!4b1!4m9!3m8!1s0x867cd728b9076403:0xe85a912320211a1a!5m2!4m1!1i2!8m2!3d25.1889533!4d-99.8225631!16s%2Fg%2F11xckh5td?entry=ttu"
    let hotel_op3 = "https://www.google.com/maps/place/Hotel+Alj%C3%B3far/@25.1886211,-99.8233304,17z/data=!3m1!4b1!4m9!3m8!1s0x867cd728a03ef469:0x60739132e93fe21e!5m2!4m1!1i2!8m2!3d25.1886211!4d-99.8233304!16s%2Fg%2F11gghd_fv5?entry=ttu"
    let hotel_op4 = "https://www.google.com/maps/place/Hotel+Alfa+Inn/@25.1898944,-99.8451693,17z/data=!3m1!4b1!4m9!3m8!1s0x867cd71d4646e745:0x9d3c36f99131da70!5m2!4m1!1i2!8m2!3d25.1898944!4d-99.8451693!16s%2Fg%2F1pxqct9hv?entry=ttu"

    let carousel_adjust = {
        backgroundColor: 'transparent',
        fontSize: '50px',
        width: '100%',
        height: '100vh',
        flexShrink: 0,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    let img_adjust = {
        borderRadius: "50%",
        height: "100vw",
        width: "100vw",
        maxWidth: "310px",
        maxHeight: "310px",
    }

    let div_adjust = {
        paddingTop: "40px",
        height: "100vh",
        marginBottom: "5px",
        justifyItems: "center",
        display: "grid",
        alignContent: "start"
    }

    let ButtonActionCarousel = styled('div')({
        color: "black"
    })

    const Slide = ({titulo="", src=false, url="", tel=""}) => {
        return(<li style={carousel_adjust}>
            <Box sx={div_adjust}>
                <TextRobotoReg sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    marginBottom: "5px",
                    borderBottom: "3px solid #b1a38a"
                }}>
                    {titulo}
                </TextRobotoReg>
                <TextRobotoReg sx={{
                    marginBottom: "30px",
                    fontSize: "20px",
                    display: "flex",
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center"
                }}><LocationCity/>Hospedaje</TextRobotoReg>
                <Box sx={{position: "relative"}}>
                    {src ? (<img src={src} style={img_adjust}/>): null}
                </Box>
                {url ? (<Box sx={{
                    color: "black",
                    background: "#f0e2ca",
                    fontSize: "20px",
                    marginTop: "30px",
                    width: "fit-content",
                    padding: "20px",
                    fontFamily: "robotoregular",
                    borderRadius: "20px",
                    textTransform: "uppercase"
                }} onClick={e=>{
                    window.open(url, '_blank')
                }}>
                    Ver mapa 
                </Box>): null}
                {tel ? (<Box sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "30px",
                    border: "3px solid #b1a38a",
                    padding: "10px"
                }}>
                    <img src={ico_tel} width="18px" height="18px" style={{
                        marginRight: "10px"
                    }}/>
                    <TextRobotoReg sx={{ fontSize: "18px", margin: "0px"}}>
                        {tel}
                    </TextRobotoReg>
                </Box>): null}
            </Box>
        </li>)
    }

    const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
  return (
    <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <Box sx={{
                background: "black",
            "& div":{
                inset: "-15px -90px !important"
            }
        }}>
            <ParallaxBannerLayer translateX={[-5,-5]} image={bkPadres} speed={-5} opacity={[0.3, 0.4]}/>
        </Box>
        <ParallaxBannerLayer>
            <Box>
                <ul
                    ref={scrollRef}
                    style={{
                    display: 'flex',
                    overflow: 'auto',
                    scrollSnapType: 'x mandatory'
                    }}
                >
                    {bkImage?.length > 0 ? bkImage.map((imagen, i) => {

                        return(<Slide
                            key={i}
                            src={imagen.value}
                            favorito={true}
                            titulo= {
                                imagen.index == 1 ? "BEST WESTERN BAZARELL INN":
                                imagen.index == 2 ? "CAPITAL O MONTE SALERNO HOTEL & SUITES":
                                imagen.index == 3 ? "HOTEL ALJÓFAR":
                                imagen.index == 4 ? "HOTEL ALFA INN": null
                            }
                            url={imagen.index == 1 ? hotel_op1: imagen.index == 2 ? hotel_op2: imagen.index == 3 ? hotel_op3: imagen.index == 4 ? hotel_op4: null}
                            tel={imagen.index == 1 ? "826 263 8000":
                                imagen.index == 2 ? "826 263 7935":
                                imagen.index == 3 ? "826 688 2488":
                                imagen.index == 4 ? "826 263 4011": null}
                        />)
                    }): null}
                    {/* {bkImage?.hospedaje1 ? (<Slide 
                        src={bkImage.hospedaje1}
                        favorito={true}
                        titulo= "BEST WESTERN BAZARELL INN"
                        url={hotel_op1}
                        tel="826 263 8000"
                    />):null}
                    {bkImage?.hospedaje2 ? (<Slide
                        src={bkImage.hospedaje2}
                        titulo= "CAPITAL O MONTE SALERNO HOTEL & SUITES"
                        url={hotel_op2}
                        tel="826 263 7935"

                    />):null}
                    {bkImage?.hospedaje3 ? (<Slide
                        src={bkImage.hospedaje3}
                        titulo= "HOTEL ALJÓFAR"
                        url={hotel_op3}
                        tel="826 688 2488"
                    />):null}
                    {bkImage?.hospedaje4 ? (<Slide
                        src={bkImage.hospedaje4}
                        titulo= "HOTEL ALFA INN"
                        url={hotel_op4}
                        tel="826 263 4011"
                    />):null} */}
                </ul>
                <Grid container sx={{
                        position: "absolute",
                        top: "40%"
                    }}>
                        <Grid item xs={6}>
                            <ButtonActionCarousel onClick={() => prev()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginLeft: "10px"
                                }}>
                                    <KeyboardDoubleArrowLeft sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                        <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                            <ButtonActionCarousel sx={{
                                textAlign: "end"
                            }} onClick={() => next()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginRight: "10px"
                                }}>
                                    <KeyboardDoubleArrowRight sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                    </Grid>
                    {/* <div>{activePageIndex + 1} / {pages.length}</div> */}
            </Box>
        </ParallaxBannerLayer>

      
    </ParallaxBanner>
    );
}

export default Hospedaje
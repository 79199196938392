import { Box, styled, Typography } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextSouthwalles } from "./Obsequios"

const VerMapaBtn = styled('div')({
    color: "black",
    background: "#d4dec4",
    width: "fit-content",
    padding: "5px",
    borderRadius: "20px",
    display: "grid",
    justifyContent: "center",
    width: "180px",
    marginTop: "20px"
})

const Religiosa = ({bkImage, maps_iglesia=null, hora_iglesia, bgcolor}) => {
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer className="contain-cover" opacity={[0.8, 0.2]} image={bkImage}/>
        <ParallaxBannerLayer translateY={[16, 24]}>
            <TextSouthwalles sx={{
                fontSize: "55px",
                letterSpacing: "1px",
                textShadow: "0px 5px 6px gray",
                color: "black",
                fontFamily: "brittany"
            }}>Discurso Biblico</TextSouthwalles>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                fontSize: "24px",
                textAlign: "center"
            }}>{hora_iglesia ? hora_iglesia: "4:00 P.M."}</Typography>
             <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                <VerMapaBtn onClick={e=>{
                    let map_dir = maps_iglesia ? maps_iglesia: "https://maps.app.goo.gl/WprJAadu6DGSVVTC7?g_st=iw";
                    var win = window.open(map_dir, '_blank');
                    win.focus();
                }}>
                    <Typography sx={{
                        fontSize: "30px",
                        fontFamily: "bright"
                    }}>VER MAPA</Typography>
                </VerMapaBtn>
            </Box>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

const Recepcion = ({bkImage, maps_salon = null, hora_salon, bgcolor}) => {
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer className="contain-cover-small" opacity={[0.8, 0.2]} image={bkImage} speed={-20} />
        <ParallaxBannerLayer translateY={[16, 24]}>
            <TextSouthwalles sx={{
                fontSize: "65px",
                letterSpacing: "2px",
                textShadow: "0px 0px 16px white",
                color: "black",
                fontFamily: "brittany"
            }}>Recepcion</TextSouthwalles>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                fontSize: "24px",
                textAlign: "center"
            }}>{hora_salon?hora_salon:"8:00 P.M."}</Typography>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                <VerMapaBtn onClick={e=>{
                    let map_dir = maps_salon ? maps_salon: "https://maps.app.goo.gl/2LJv3YzHQ8pk7rVv6";
                    var win = window.open(map_dir, '_blank');
                    win.focus();
                }}>
                    <Typography sx={{
                        fontSize: "30px",
                        fontFamily: "bright"
                    }}>VER MAPA</Typography>
                </VerMapaBtn>
            </Box>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

const Ubicaciones = ({imagenes, maps_iglesia, maps_salon, hora_iglesia, hora_salon, bgcolor}) => {
    return(<Box>
        <Religiosa bgcolor={bgcolor} hora_iglesia={hora_iglesia} maps_iglesia={maps_iglesia} bkImage={imagenes?.religiosa ? imagenes.religiosa: false}/>
        <Recepcion bgcolor={bgcolor} hora_salon={hora_salon} maps_salon={maps_salon} bkImage={imagenes?.recepcion ? imagenes.recepcion: false}/>
    </Box>)
}

export default Ubicaciones
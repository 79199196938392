import { Box, Typography } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextRoboto, TextSouthwalles } from "."
import dresscode from '../../images/traje_y_vestido-removebg-preview.png'

const Dresscode = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer className="contain-cover" image={bkImage} speed={-5} />
        <ParallaxBannerLayer opacity={[0, 2.5]} translateY={[10, 5]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextSouthwalles sx={{
                    fontSize: "65px",
                    color: "white",
                    letterSpacing: "1px",
                    fontFamily: "brittany"
                }}>Dresscode</TextSouthwalles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[31, 10]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <Box sx={{
                    background: "white",
                    width: "100px",
                    height: "100px",
                    opacity: 0.8,
                    borderRadius: "50%"
                }}>
                    <img src={dresscode} style={{
                        width: "inherit",
                        padding: "10px"
                    }}/>
                </Box>
            </Box>
            <Typography sx={{
                fontSize: "30px",
                fontFamily: "bright",
                textAlign: "center",
                color: "white"
            }}>Formal</Typography>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
}

export default Dresscode
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { Box, styled, Typography } from "@mui/material"
import bbva_img from '../../images/BBVA IMA.png'
import sobre from '../../images/sobre-removebg-preview.png'

const Obsequios = ({n_cuenta, bgcolor}) => {    
  return (
    <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        <ParallaxBannerLayer translateY={[38, 20]}>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Lo más importante es tu presencia, pero si deseas hacernos un regalos, puede ser</Typography>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[8, 0]}>
            <TextSouthwalles sx={{
                fontSize: "65px",
                color: "black",
                fontFamily: "brittany"
            }}>Obsequios</TextSouthwalles>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[45, 30]}>
            <Box sx={{display: "grid", width: "100%", justifyContent: "center"}}>
                <img src={sobre} style={{
                    width: "inherit",
                    height: "160px",
                    width: "220px"
                }}/>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[58, 25]}>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Lluvia de sobres</Typography>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>ó</Typography>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Transferencia</Typography>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[74, 25]}>
            <Box sx={{display: "grid", width: "100%", justifyContent: "center"}}>
                <img src={bbva_img} style={{
                    width: "inherit",
                    height: "60px",
                    width: "160px"
                }}/>
            </Box>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>Madai Cortés Cruz</Typography>
            <Typography sx={{
                fontFamily: "belleza_reg",
                color: "black",
                textAlign: "center",
                padding: "0px 20px",
                fontSize: "18px"
            }}>{n_cuenta ? n_cuenta: ""}</Typography>
        </ParallaxBannerLayer>
    </ParallaxBanner>
    );
}

export default Obsequios

export const TextSouthwalles = styled('p')({
    fontFamily: "'southwalles'",
    letterSpacing: "6px",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})
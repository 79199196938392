import { Box, Typography } from "@mui/material"
import { Auth } from "../context";
import { useContext } from "react";
import { ExitToApp } from "@mui/icons-material";

export const Footer = () => {
    const authContext = useContext(Auth);
    const { cerrarSesion } = authContext;

    return(<Box sx={{position: "relative", background: "black", display: "grid", padding:"10px 10px"}}>
        <Typography sx={{fontFamily: "'robotoregular'", fontSize: "12px", textAlign: "center"}}>
            Desarrollado por <a href="https://convitte.com/" style={{
                textDecoration: "none",
                color: "#f6aaa6",
                fontWeight: "700"
            }}>Convitte</a>
        </Typography>
        <ExitToApp sx={{position: "absolute", right: 4, top: 6, cursor: "pointer"}} onClick={e=> {
            cerrarSesion()
        }}/>
    </Box>)
}

export const Invitacion = ({children}) => {
    return(<Box id="main-all-container">
        <Box className="area-container-grid">
            {children}
        </Box>
    </Box>)
}
import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import triangleTop from '../../images/top-triangle.png'
import triangleBottom from '../../images/bottom-triangle.png'
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { TextCarios, TxtSubTitles } from "./Textos";
import { Auth } from "../../context";

// const Confirmation = ({bkImage, guest, evento, open, setOpen}) => {
//     const authContext = useContext(AuthContext);
//     const { confirmarAsistencia, confirmado } = authContext;
//     const handleOpen = () => { setOpen(true) };
//     const handleClose = () => { setOpen(false) };
//     const [confirm, setConfirm] = useState(0);
//     const [asistidos, setAsistidos] = useState(0);
  
//     const aceptarAsistencia = (noAsistire = false) => {
//       confirmarAsistencia(noAsistire ? 1010: asistidos)
//       handleClose()
//     }
  
//     const onChange = e => {
//       if(e?.target?.value && e?.target?.value > 0 && guest?.reservaciones){
//         if(e?.target?.value <= guest?.reservaciones){
//           setAsistidos(e.target.value)
//         }
//       } 
//     }
  
//     useEffect(()=>{if(guest) setConfirm(confirmado ? true: false) },[confirmado])
  
//     useEffect(()=> {
//       if(guest){
//         setConfirm(guest?.confirm ? guest.confirm: 0)
//         setAsistidos(guest?.reservaciones ? guest.reservaciones: 0)
//       }  
//     },[guest])
  
//     return(<Box>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="parent-modal-title"
//         aria-describedby="parent-modal-description"
//         className='test-kdemslkamds'
//         sx={{
//           display: "grid",
//           alignContent: "center",
//           justifyContent: "center"
//         }}
//       >
//         <Box sx={{outline: "0px !important", paddingX: "15%", position: "relative"}}>
//           <Close onClick={handleClose} sx={{
//             cursor: "pointer",
//             color: "white !important",
//             position: "absolute",
//             top: "0px",
//             right: "30px"
//           }}/>
//           <Typography variant='h5' sx={{textAlign: "center", color:"white", fontFamily: "'Antic Didone'", fontSize: "30px", fontWeight: "bold"}}>¿Deseas confirmar tu asistencia?</Typography>
//           <Typography variant='subtitle2' sx={{fontFamily: "'Antic Didone'", marginBottom: '40px', fontSize: "20px", textAlign: "center", color:"white"}}>
//             Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
//           </Typography>
//           <Grid container sx={{height: '60px'}} spacing={2}>
//             <Grid item xs={3} sx={{textAlign: 'center', padding: "0px !important"}}>
//               <Button onClick={()=>{              
//                 if(asistidos && asistidos > 0){
//                   if(asistidos < guest?.reservaciones){
//                     setAsistidos(asistidos+1)
//                   }
//                 } 
//               }}><Add sx={{color: "white !important", background: "black", padding: "5px 10px"}}/></Button>
//               <TextField
//                 variant='standard'
//                 type='number'
//                 value={asistidos}
//                 onChange={onChange}
//                 sx={{"& input":{color: "white !important", textAlign: "center"}}}
//               />
//               <Button onClick={()=>{              
//                 if(asistidos && (asistidos-1) > 0 && guest?.reservaciones){
//                   setAsistidos(asistidos-1)
//                 } 
//               }}><Remove sx={{color: "white !important", background: "black", padding: "5px 10px"}}/></Button>
//             </Grid>
//             <Grid item xs={9} sx={{textAlign: 'left', color: "white", display: "grid", alignContent: "center"}}>
//               Número de personas
//             </Grid>
//             <Grid
//               onClick={() => aceptarAsistencia()} 
//               item xs={5} sx={{marginTop: "30px", color: "white", background: "black", textAlign: "center", padding: "20px 10px", borderRadius: "5px"}}>
//               Asistire
//             </Grid>
//             <Grid item xs={2}></Grid>
//             <Grid  
//               onClick={() => aceptarAsistencia(1010)} 
//              item xs={5} sx={{marginTop: "30px", color: "white", background: "black", textAlign: "center", padding: "20px 10px", borderRadius: "5px"}}>
//               No Asistire
//             </Grid>
//           </Grid>
//         </Box>
//       </Modal>
//       <Box sx={{position: "relative"}}>
//         <ParallaxBanner
//             layers={[{
//                 image: bkImage,
//                 translateY: [10, -10],
//                 opacity: [1, 1],
//             },
//             {
//                 translateY: [-60, 0],
//                 shouldAlwaysCompleteAnimation: true,
//                 expanded: false,
//                 children: (
//                 <Box
//                     sx={{
//                     display: "grid",
//                     height: '100vh',
//                     alignContent: "center",
//                     justifyContent: "center"
//                 }}>
//                     <Grid container sx={{width: '100vw', marginTop: "30%"}}>
//                     <Grid item xs={12} sx={{display: "grid", justifyContent: 'center', alignContent: "center"}}> 
//                         {/* <CardGiftcard sx={{fontSize: "80px", color: "white"}}/> */}
//                         <TextCarios sx={{
//                         textAlign: "center", 
//                         color: "white",
//                         textShadow: "0px 2px 3px black",
//                         fontSize: "40px !important",
//                         // fontFamily: "'Sacramento'",
//                         fontWeight: '700'
//                         }}>
//                         {confirm <= 0 && guest?.tipoInvitacion != 3 ? "Confirmanos tu":  guest?.tipoInvitacion == 3 ? "": "Gracias por confirmar"}
//                         </TextCarios>
//                     </Grid>
//                     <Grid item xs={1} md={2} sx={{display: "grid", alignContent: "center"}}></Grid>
//                     <Grid item xs={10} md={8}>
//                         {confirm <= 0 && guest?.tipoInvitacion != 3 ? (
//                             <Typography
//                             onClick={handleOpen}
//                             variant='h6' sx={{
//                                 textAlign: "center", 
//                                 fontFamily: "serif",
//                                 color: "white", 
//                                 textShadow: "0px 2px 3px black",
//                                 fontSize: "30px !important",
//                                 fontFamily: "'Antic Didone'",
//                                 fontWeight: '700',
//                                 backdropFilter: "blur(15px)",
//                                 borderBottom: "1px solid white",
//                                 borderRadius: "5px",
//                                 boxShadow: "0px 5px 10px -3px white"
//                             }}>Asistencia</Typography>
//                         ):(<Grid container>
//                         <Grid>
//                         {/* <Typography
//                             variant='h6' sx={{
//                             textAlign: "center", 
//                             fontFamily: "serif",
//                             color: "white", 
//                             letterSpacing: "-1px",
//                             lineHeight: "20px"
//                         }}>
//                             Para mí eres muy importante, deseo que puedas confirmar tu asistencia al evento
                            
//                             </Typography> */}
//                             {/* Estamos felices de compartir con ustedes este día especial por lo cual 
//                         <b style={{fontFamily: "'Sacramento'", fontSize: "35px", marginLeft: "5px", lineHeight: "0px"}}>
//                             tu asistencia es importante</b> */}
//                         </Grid>
//                         <Grid item xs={12} sx={{display: "grid", justifyContent: "center"}}>
//                         </Grid>
//                         </Grid>)}
//                     </Grid>
//                     <Grid item xs={1} md={2} sx={{display: "grid", alignContent: "center"}}></Grid>
//                     </Grid>
//                     </Box>
//                 )
//             }
//         ]}
//           className="maxHeight"
//         />
//       </Box>
//     </Box>)
// }

const Confirmacion = ({bkImage, guest, evento, open, setOpen, bgcolor}) => {
        const authContext = useContext(Auth);
        const { confirmarAsistencia, confirmado } = authContext;
        const handleOpen = () => { setOpen(true) };
        const handleClose = () => { setOpen(false) };
        const [confirm, setConfirm] = useState(0);
        const [asistidos, setAsistidos] = useState(0);
      
        const aceptarAsistencia = (noAsistire = false) => {
          confirmarAsistencia(noAsistire ? 1010: asistidos)
          handleClose()
        }
      
        const onChange = e => {
          if(e?.target?.value && e?.target?.value > 0 && guest?.reservaciones){
            if(e?.target?.value <= guest?.reservaciones){
              setAsistidos(e.target.value)
            }
          } 
        }
      
        useEffect(()=>{
            console.log(confirmado)
            if(guest)setConfirm(confirmado ? true: false)
        },[confirmado])
      
        useEffect(()=> {
            console.log(guest.confirm)
            console.log(confirm)
          if(guest){
            setConfirm(guest?.confirm ? guest.confirm: 0)
            setAsistidos(guest?.reservaciones ? guest.reservaciones: 0)
          }  
        },[guest])
    
    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        borderRadius: "8px",
        p: 2

        // "& p, h5, input, div": {
        //     color: "black !important"
        // }
    };

    const TxtTitle = styled('p')({
        color: "black",
        fontFamily: "'Antic Didone'",
        fontWeight: "bold",
        fontSize: "18px"
    })
    
    const TxtSub = styled('p')({
        color: "black",
        fontSize: "15px",
        fontFamily: "'Antic Didone'"
    })

    return (<Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className='test-kdemslkamds'
            sx={{
            display: "grid",
            alignContent: "center",
            justifyContent: "center"
            }}
        >
            <Box sx={{outline: "0px !important", ...style}}>
                <Close onClick={handleClose} sx={{
                    cursor: "pointer",
                    color: "black !important",
                    position: "absolute",
                    top: "10px",
                    right: "10px"
                }}/>
                <TxtTitle>¿Deseas confirmar tu asistencia?</TxtTitle>
                <TxtSub sx={{marginTop: "10px"}}>
                    Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
                </TxtSub>
                <Grid container sx={{marginTop: "20px"}}>
                    <Grid item xs={5}>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <Button onClick={()=>{
                                    if(asistidos && asistidos > 0){
                                        if(asistidos < guest?.reservaciones){
                                            setAsistidos(asistidos+1)
                                        }
                                    } 
                                }}><Add sx={{color: "white !important", background: "black"}}/></Button>
                            </Grid>
                            <Grid item xs={8} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <TextField
                                    variant='standard'
                                    type='hidden'
                                    value={asistidos}
                                    onChange={onChange}
                                    // sx={{"& input":{color: "black !important", textAlign: "center", fontSize: "12px"}}}
                                />
                                <TxtSub sx={{fontWeight: "bold"}}>
                                    {asistidos} persona{asistidos > 1? "s": ""}
                                </TxtSub>
                            </Grid>
                            <Grid item xs={2} sx={{ display: "grid", alignContent: "center", justifyContent: "center" }}>
                                <Button onClick={()=>{
                                    if(asistidos && (asistidos-1) > 0 && guest?.reservaciones){
                                        setAsistidos(asistidos-1)
                                    } 
                                }}>
                                    <Remove sx={{color: "white !important", background: "black"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <TxtSub
                            onClick={() => aceptarAsistencia()}
                            sx={{
                                color: "white",
                                background: "black",
                                textAlign: "center",
                                padding: "7px 5px",
                                borderRadius: "5px"
                        }}>Confirmar</TxtSub>
                    </Grid> 
                </Grid>
            </Box>
        </Modal>
        <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
            <ParallaxBannerLayer translateY={[16, 16]} opacity={[0.4, 1.5]}>
                <TextCarios sx={{
                    color: "black",
                    padding: "0px",
                    marginTop: "50px !important",
                    fontSize: "50px"
                }}>Confirmar Asistencia</TextCarios>
                <Box sx={{marginTop: "40px"}}>
                    <TxtSubTitles sx={{color: "black", padding: "0px", fontSize: "12px"}}>
                        ESPERAMOS QUE NOS PUEDAN ACOMPAÑAR 
                    </TxtSubTitles>
                </Box>
                {confirm > 0 ? (<TextCarios sx={{marginTop: "50px", fontSize: "40px"}}>Gracias</TextCarios>): (<Grid container sx={{marginTop: "60px"}}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4} sx={{
                        padding: "5px"
                    }}>
                        <Box
                            onClick={handleOpen}
                            sx={{
                                background: "#D6BDC0",
                                borderRadius: "8px",
                                padding: "12px",
                                minHeight: "70px",
                                display: "grid",
                                alignContent: "center"
                            }}
                        >
                            <TxtSubTitles sx={{
                                fontSize: "12px",
                                color: "black",
                                padding: "0px",
                                fontWeight: "bold"
                            }}>SI, POR NADA ME LO PIERDO</TxtSubTitles>
                        </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4} sx={{
                        padding: "5px"
                    }}>
                        <Box sx={{
                            background: "#D6BDC0",
                            borderRadius: "8px",
                            padding: "12px",
                            minHeight: "70px",
                            display: "grid",
                            alignContent: "center"

                        }}>
                            <TxtSubTitles onClick={e=>aceptarAsistencia(1010)} sx={{
                                fontSize: "12px",
                                color: "black",
                                padding: "0px",
                                fontWeight: "bold"
                            }}>NO PODRE ASISTIR, LO LAMENTO</TxtSubTitles>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>)}
                <Box sx={{padding: "50px"}}>
                    <TxtSubTitles sx={{color: "black", padding: "0px", fontSize: "12px"}}>
                        AYUDANOS CONFIRMANDO TU ASISTENCIA LO ANTES POSIBLE 
                    </TxtSubTitles>
                </Box>
                <Grid container sx={{
                    background: "black",
                    height: "32vh",
                    padding: "2vh 2vw"
                }}>
                    {bkImage?.length > 0 ? bkImage.map((img, i_img) => {
                        return(<Grid item xs={4} key={i_img}>
                            <Box sx={{
                                display: "grid",
                                alignContent: "center",
                                justifyContent: "center"
                            }}>
                                <img
                                    src={img}
                                    style={{
                                        maxWidth: "29vw",
                                        minWidth: "29vw",
                                        minHeight: "28vh",
                                        maxHeight: "28vh"
                                }}/>
                            </Box>
                        </Grid>)
                    }):null}
                </Grid>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    </Box>);
}

export default Confirmacion
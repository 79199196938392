import { Box, Grid } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useEffect, useState } from "react"
import { TxtSubTitles } from "./Textos"
import img_1 from './assets/invitado_4.png'

const Invitado = ({bkImage, invitado, evento, bgcolor}) => {
    const [nombre, setNombre] = useState("")
    const [dia, setDia]     = useState("01")
    const [mes, setMes]     = useState("01")
    const [anio, setAnio]   = useState("2024")

    const [reservaciones, setReservacion] = useState(0)
    useEffect(()=>{
        console.log(evento)
        if(invitado?.nombre){
            setNombre(invitado.nombre)
        }

        if(invitado?.reservaciones){
            setReservacion(invitado.reservaciones)
        }
    },[invitado])

    useEffect(()=>{
        if(evento?.dtEvento){
            let date_get = new Date(evento.dtEvento)
            setDia(date_get.getDate())
            setMes(date_get.getMonth()+1)
            setAnio(date_get.getFullYear())
        }
    },[evento])

    let phrase = "Pronto nuestro hogar se iluminará con la ternura de una hermosa sonrisa, acompáñanos a celebrar la llegada de nuestra bebé"
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor }}>
        {/* <ParallaxBannerLayer scaleX={[0.99, 1]} image={bkImage} speed={-5} /> */}
        <ParallaxBannerLayer opacity={[-1, 3.5]} translateY={[-3, -3]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
            <TxtSubTitles sx={{
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "16px !important",
                letterSpacing: "5px",
                padding: "8% 2%",
                lineHeight: 1.6
            }}>{phrase.toUpperCase()}</TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 3.5]} translateY={[31, 31]}>
            <Grid container sx={{width: "100% !important"}}>
                {bkImage?.length > 0 ? bkImage.map((item_img, i_index) => {
                    return(<Grid item xs={4}>
                        <Box sx={{
                            padding: "10px 5px"
                        }}>
                            <img src={item_img} style={{
                                minHeight: "180px"
                            }} width="100%"/>
                        </Box>
                </Grid>)}):null}
            </Grid>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 3.5]} translateY={[47, 55]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px", position: "relative"}}>
                <Box sx={{
                    maxWidth: "280px",
                    minWidth: "280px",
                    padding: "0px",
                    height: "100px",
                    position: "relative"
                }}>
                    <TxtSubTitles sx={{
                        fontSize: "20px !important",
                        height: "34px",
                        padding: "10px",
                        zIndex:3,
                        width: "100%",
                        position: "absolute",
                        top: 2,
                        textAlign: "center",
                        color: "black"
                    }}>{nombre.toUpperCase()}</TxtSubTitles>
                    <TxtSubTitles sx={{
                        fontSize: "16px !important",
                        margin: "0px",
                        padding: "0px",
                        width: "100%",
                        position: "absolute",
                        bottom: 5,
                        textAlign: "center",
                        zIndex:"4 !important",
                        paddingBottom: "10px",
                        color: "black"
                    }}>{`(${reservaciones})`}</TxtSubTitles>
                </Box>
                <Box sx={{display: "grid", justifyContent: "center"}}>
                    <img src={img_1} width="250px"/>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            bottom: "80px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                    
            <TxtSubTitles sx={{
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "16px !important",
                letterSpacing: "5px",
                padding: "0px",
            }}>Nos vemos el día</TxtSubTitles>

            <TxtSubTitles sx={{
                fontFamily: "'balgin'",
                color: "#FF6D6D",
                fontSize: "42px !important",
                letterSpacing: "5px",
                padding: "0px",
            }}>{dia > 9 ? dia: `0${dia}`} | {mes > 9 ? mes: `0${mes}`} | {anio}</TxtSubTitles>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Invitado
import { Box, styled, Typography } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TxtSubTitles } from "./Textos"
import { CalendarMonth, Place, Schedule } from "@mui/icons-material"
import img_2 from "./assets/recepcion_2.png"

const VerMapaBtn = styled('div')({
    color: "black",
    background: "#FFDBA1",
    width: "fit-content",
    padding: "5px",
    borderRadius: "20px",
    marginTop: "25px",
    minWidth: "150px"
})

const Recepcion = (props) => {
    let {
        bkImage,
        bgcolor = "#FFF2EE",
        hora_salon,
        maps_salon,
        fechaEvento,
        direccion
    } = props;

    let dir = direccion ? direccion: "SMILE LAB Paseo de los Leones 169, Cumbres Elite 4to. Sector, 64349 Monterrey, N.L."

    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: bgcolor}}>
            <ParallaxBannerLayer translateY={[0, 0]} opacity={[-1, 3]} style={{position: "relative", height: "100vh"}}>
                <Box sx={{height: "40vh", width: "100%", padding: "1vh", marginTop: "20px"}}>
                    <img src={bkImage} style={{width: "100%", height: "35vh"}}/>
                </Box>
                <TextCarios sx={{color: "#a76c40", padding: "0px", marginTop: "10px !important", fontSize: "50px"}}>
                    Recepción
                </TextCarios>
                <Box sx={{marginTop: "20px"}}>
                    <Box sx={{marginTop: "25px"}}>
                        <Box sx={{display: "block", padding: "0px 50px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "16px",
                                padding: "0px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "'balgin'"
                            }}><Place sx={{color: "#a76c40", fontSize: "24px", marginRight: "10px"}}/>{dir}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "25px"}}>
                        <Box sx={{display: "block", padding: "0px 30px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "16px",
                                padding: "0px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "'balgin'"
                            }}><CalendarMonth sx={{color: "#A76C40", fontSize: "24px", marginRight: "15px"}}/>{fechaEvento ? fechaEvento: "Domingo 20 de Octubre 2024"}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "25px"}}>
                        <Box sx={{display: "block", padding: "0px 30px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "16px",
                                padding: "0px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "'balgin'"
                            }}><Schedule sx={{color: "#A76C40", fontSize: "24px", marginRight: "15px"}}/>{hora_salon ? hora_salon: "10:30 a.m."}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{display: "grid", justifyContent: "center", zIndex: 3}}>
                        <VerMapaBtn>
                            <Typography onClick={e=>{
                                let map_dir = maps_salon ? maps_salon: "https://maps.app.goo.gl/3JSDtHjPRyBN3Uc67"
                                var win = window.open(map_dir, '_blank');
                                // Cambiar el foco al nuevo tab (punto opcional)
                                win.focus();
                            }} sx={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                fontFamily: "balgin",
                                textAlign: "center",
                                // padding: "5px"
                            }}>VER MAPA</Typography>
                        </VerMapaBtn>
                    </Box>
                </Box>
                <Box sx={{position: "absolute", bottom: 60, right: 0, width: "100%", display: "grid", justifyItems: "end", zIndex:-3}}>
                    <img src={img_2} width="60%" style={{maxWidth: "80px", opacity: 1}}/>
                </Box>
            </ParallaxBannerLayer>
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

export default Recepcion